import { useContext } from 'react';
import { formatLocale, FormatLocaleDefinition } from 'd3-format';

import { SessionContext } from '../auth';
import { IProfile } from '../types/users';

export const getFullYearFormat = (profile: IProfile) => (
  profile.dateFormat === 'month-first'
    ? 'MMM D, YYYY'
    : 'DD/MM/YYYY'
);

export const useFullYearFormat = () => {
  const { profile } = useContext(SessionContext);
  return getFullYearFormat(profile as IProfile);
}

export const useShortYearDateFormat = () => {
  const { profile } = useContext(SessionContext);
  return (
    profile!.dateFormat === 'month-first'
      ? 'MM/DD/YY'
      : 'DD/MM/YY'
  );
}

export const useNoYearDateFormat = () => {
  const { profile } = useContext(SessionContext);
  return (
    profile!.dateFormat === 'month-first'
      ? 'MMM D'
      : 'DD/MM'
  );
}

export const useNoLeadingZerosDateFormat = () => {
  const { profile } = useContext(SessionContext);
  return (
    profile!.dateFormat === 'month-first'
      ? 'M/D/YYYY'
      : 'D/M/YYYY'
  );
}

const getBaseFormatOptions = (profile: IProfile): FormatLocaleDefinition => ({
  decimal: profile.currencyDecimal === 'comma' ? ',' : '.',
  thousands: {
    none: '',
    space: ' ',
    comma: ',',
    apostrophe: "'",
  }[profile.currencyGrouping],
  grouping: [3],
  currency: ['', ''],
});

export const makeFormatCurrency = (profile: IProfile, fractionDigits = 0) => ({ value }: { value?: number | null }) => {
  if (value === null || value === undefined || Number.isNaN(value)) {
    return '-';
  }
  const { format } = formatLocale({
    ...getBaseFormatOptions(profile),
    currency: ['$', ''],
  });
  return format(`($,.${fractionDigits}f`)(value);
};

export const makeFormatPercent = (profile: IProfile) => ({ value }: { value: number | null }, { sign }: { sign?: boolean } = {}) => {
  if (value === null || value === undefined || Number.isNaN(value)) {
    return '-';
  }
  const { format } = formatLocale(getBaseFormatOptions(profile));
  return format(`${sign ? '+' : ''},.2%`)(value / 100);
};

export const makeFormatNumber = (profile: IProfile) => ({ value }: { value: number }) => {
  if (value === undefined) {
    return '-';
  }
  const { format } = formatLocale({
    ...getBaseFormatOptions(profile),
  });
  return format(',.0f')(value);
};

export const makeFormatCurrencyList = (profile: IProfile, fractionDigits = 2) => {
  const formatCurrency = makeFormatCurrency(profile, fractionDigits);
  return ({ value }: { value: number[] }) => value.map((x) => (
    x === undefined ? '-' : formatCurrency({ value: x })
  )).join('\n');
}

export const makeFormatPercentList = (profile: IProfile) => ({ value }: { value: number[] }) => {
  const formatPercent = makeFormatPercent(profile);
  return value.map((x) => x === undefined ? '-' : formatPercent( { value: x })).join('\n');
};

export const makeFormatNumberList = (profile: IProfile) => ({ value }: { value: number[] }) => {
  const formatNumber = makeFormatNumber(profile);
  return value.map((x) => formatNumber({ value: x })).join('\n');
};

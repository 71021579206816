import React, { useState } from 'react';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Button from '@mui/material/Button';

import { supabase } from '../../auth';
import { StyledTypography } from '../../components/Auth/Styled';

interface ForgotPasswordForm {
  email: string;
}

export default function ForgotPassword() {
  const { state } = useLocation();
  const formContext = useForm<ForgotPasswordForm>({
    resolver: yupResolver(yup.object().shape({
      email: yup.string().required().email('Invalid email address'),
    })),
    defaultValues: {
      email: state?.email ?? '',
    },
  });
  const { handleSubmit } = formContext;
  const [emailSent, setEmailSent] = useState<string | null>(null);
  const onSubmit = async ({ email }: ForgotPasswordForm) => {
    await supabase.auth.resetPasswordForEmail(email);
    setEmailSent(email);
    formContext.reset();
  };
  return (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(onSubmit)}
      FormProps={{ style: { display: 'flex', flexDirection: 'column' } }}
    >
      <StyledTypography sx={{ margin: '0 0 12px 0' }}>
        Enter your email address to get a password reset link.
      </StyledTypography>
      <TextFieldElement label="Email" name="email" sx={{ marginBottom: '12px' }} />
      <Button variant="contained" sx={{ alignSelf: 'flex-end' }} type="submit">
        Send reset link
      </Button>
      {emailSent && (
        <StyledTypography sx={{ margin: '12px 0 0 0' }}>
          Password reset link has been sent to {emailSent}.
        </StyledTypography>
      )}
    </FormContainer>
  );
}

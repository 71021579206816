import React, { useContext } from 'react';
import { ReactComponent as AlphanumericAscending } from 'assets/icons/alphanumeric-ascending.svg';
import { ReactComponent as AlphanumericDescending } from 'assets/icons/alphanumeric-descending.svg';
import { ReactComponent as NumericAscending } from 'assets/icons/numeric-ascending.svg';
import { ReactComponent as NumericDescending } from 'assets/icons/numeric-descending.svg';
import { ReactComponent as OtherAscending } from 'assets/icons/other-ascending.svg';
import { ReactComponent as OtherDescending } from 'assets/icons/other-descending.svg';

import { HeaderContext } from '../useGridColumnHeaders';

export function SortedDescendingIcon({ className }: { className?: string }) {
  const { columnType } = useContext(HeaderContext);
  if (columnType === 'string') {
    return <AlphanumericDescending className={className} />;
  }
  if (columnType === 'number') {
    return <NumericDescending className={className} />;
  }
  return <OtherDescending className={className} />;
}

export function SortedAscendingIcon({ className }: { className?: string }) {
  const { columnType } = useContext(HeaderContext);
  if (columnType === 'string') {
    return <AlphanumericAscending className={className} />;
  }
  if (columnType === 'number') {
    return <NumericAscending className={className} />;
  }
  return <OtherAscending className={className} />;
}
import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/system';

interface TopPanelProps {
  selectEnabled: boolean;
  setSelectEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
  filtersEnabled: boolean;
  onClearFilters: () => void;
}

export default function TopPanel({ selectEnabled, setSelectEnabled, children, filtersEnabled, onClearFilters }: TopPanelProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        height: '48px',
        display: 'flex',
        padding: '12px 24px',
        boxShadow: '0px 2px 5px rgba(142, 142, 142, 0.2), 0px 2px 10px rgba(142, 142, 142, 0.14), 0px 5px 14px rgba(142, 142, 142, 0.12)',
      }}
    >
      <Box
        sx={{
          margin: '-8px 0',
          padding: '8px 24px 8px 0',
          display: 'flex',
          alignItems: 'center',
          borderRight: '1px solid #D6D6D6',
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() => setSelectEnabled(!selectEnabled)}
        >
          {selectEnabled ? 'Clear selection' : 'Select'}
        </Button>
        <Button
          variant="outlined"
          size="small"
          disabled={!filtersEnabled}
          onClick={onClearFilters}
          sx={{ marginLeft: '10px' }}
        >
          Clear All Filters
        </Button>
      </Box>
      <Box sx={{ alignSelf: 'flex-end', display: 'flex', paddingLeft: '24px', marginBottom: '4px' }}>
        {[
          {
            value: 'CC',
            label: 'Cart Creator',
          },
          {
            value: 'CS',
            label: 'Cart Saver',
          },
        ].map(({ value, label }) => (
          <Box
            key={value}
            sx={{
              marginRight: '14px',
              display: 'flex',
              fontFeatureSettings: "'tnum' on, 'lnum' on",
              alignItems: 'center',
              [theme.breakpoints.down('lg')]: {
                marginRight: '8px',
              },
            }}
          >
            <Typography
              sx={{
                padding: '0 6px',
                fontSize: '10px',
                lineHeight: '10px',
                height: '14px',
                color: 'white',
                background: theme.palette.secondary.dark,
                fontWeight: 800,
                border: '1px solid',
                borderRadius: '50px',
                fontVariant: 'all-small-caps',
              }}
            >
              {value}
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '14px',
                color: theme.palette.primary.dark,
                fontWeight: 800,
                marginLeft: '4px',
                marginBottom: '2px',
                fontVariant: 'small-caps',
              }}
            >
              {label}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          marginLeft: 'auto',
          display: 'flex',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

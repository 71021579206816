import React, { useContext } from 'react';
import { ReactComponent as ArrowDropDown } from 'assets/icons/arrow-drop-down.svg';

import { Box } from '@mui/system';
import {
  gridFilterModelSelector,
  GridRenderCellParams, GridTreeNode,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid-pro';

import type { IGrossRow, IIncrementalRow } from '../../types/global';
import { ExpandedRowsContext } from '../DashboardGroupingCell';
import { numberOperators } from '../FilterPanel/operators';

export default function NumberCell(props: GridRenderCellParams<IIncrementalRow | IGrossRow>): React.ReactNode {
  const { formattedValue, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const rowDoesNotMatch = filterModel.items.some((item) => (
    item.field === field &&
    !numberOperators.find((x) => x.value === item.operator)!
      .getApplyFilterFn(item, null as any)!(props)
  ));
  const { rows, setRows } = useContext(ExpandedRowsContext);
  const toggleRow = (node: GridTreeNode, expand?: boolean) => {
    const newExpanded = expand ?? !rows[node.id];
    apiRef.current.setRowChildrenExpansion(node.id, newExpanded);
    setRows((currentRows) => ({ ...currentRows, [node.id]: newExpanded }));
    apiRef.current.setCellFocus(node.id, field);
    (node as any).children?.forEach((child: string) => {
      const childNode = apiRef.current.getRowNode(child);
      if (childNode && childNode.type === 'group') {
        toggleRow(childNode, expand);
      }
    });
  };
  const onClick = () => toggleRow(rowNode);
  return (
    <>
      {rowDoesNotMatch && (
        <Box onClick={onClick} component={ArrowDropDown} sx={{ fill: '#8BD267', marginBottom: '2px', cursor: 'pointer' }} />
      )}
      <Box className="MuiDataGrid-cellContent">
        {formattedValue}
      </Box>
    </>
  );
}


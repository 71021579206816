import { AxiosResponse } from 'axios';
import { IDomain } from 'types/domains';

import { IUploaded } from '../types/global';

import axios, { API_URL } from './config';

export const createDomainAPI = async (id: string | number, body: Omit<IDomain, 'id'>) =>
  axios.post(`${API_URL}/organizations/${id}/domains`, body);

export const updateDomainAPI = async (id: string, domid: string, body: Omit<IDomain, 'id' | 'logoUrl'>) =>
  axios.put(`${API_URL}/organizations/${id}/domains/${domid}`, body);

export const removeDomainAPI = async (id: string, domid: string) =>
  axios.delete(`${API_URL}/organizations/${id}/domains/${domid}`);

export const getProductsAPI = async () => axios.get(`${API_URL}/products`);

export const logoUploadAPI = async (data: FormData): Promise<AxiosResponse<IUploaded>> => {
  const response = await axios.post(`${API_URL}/upload`, data);
  return { ...response, data: { uploadedId: response.data.uploadedId, uploadedUrl: response.data.uploadedLogo } };
};

import React, { useEffect, useState } from 'react';
import { getProductsAPI } from 'apis/domains';
import { getOrganizationsAPI } from 'apis/organizations';
import AddOrganizationBar from 'modules/screens/organization/AddOrganizationBar';
import EditDomainForm from 'modules/screens/organization/EditDomainForm';
import EditOrganizationForm from 'modules/screens/organization/EditOrganizationForm';
import OrganizationsTable from 'modules/screens/organization/OrganizationsTable';
import type { IProduct } from 'types/domains';
import type { IEditFormStatus, IOrganization } from 'types/organizations';

import Grid from '@mui/material/Grid';

export default function ManageOrganizationPage() {
  const [tableData, setTableData] = useState<IOrganization[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);

  const [editFormState, setEditFormState] = useState<IEditFormStatus>({
    type: undefined,
  });
  const fetchOrganizations = async () => {
    const response = await getOrganizationsAPI();
    setTableData(response.data);
  };

  async function fetchProducts() {
    const response = await getProductsAPI();
    setProducts(response.data);
  }

  async function resetTable() {
    await fetchOrganizations();
    setEditFormState({ type: undefined });
  }

  useEffect(() => {
    fetchOrganizations();
    fetchProducts();
  }, []);

  return (
    <Grid container direction='row' sx={{ paddingTop: 8 }}>
      <Grid item xs={6}>
        <AddOrganizationBar openForm={() => setEditFormState({ type: 'organization' })} />
        <OrganizationsTable
          organizations={tableData}
          setEditStatus={(value) => setEditFormState(value)}
        />
      </Grid>
      <Grid item xs={6} paddingX={13} sx={{ position: 'sticky', top: '70px', alignSelf: 'flex-start' }}>
        {editFormState.type &&
          (editFormState.type === 'domain' ? (
            <EditDomainForm
              data={editFormState.data}
              products={products}
              updateData={() => resetTable()}
            />
          ) : (
            <EditOrganizationForm data={editFormState.data} updateData={() => resetTable()} />
          ))}
      </Grid>
    </Grid>
  );
}

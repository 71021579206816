import React, { useState } from 'react';

import { useTheme } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

import FilterPanel from '../FilterPanel';

import ColumnHeaders from './ColumnHeaders';
import FilterButton from './FilterButton';
import { SortedAscendingIcon, SortedDescendingIcon } from './SortIcons';

type Props = {
  columns: GridColDef[];
  rows: any[];
  initialSortModel?: GridSortModel;
};

function CustomDataGrid({ columns, rows, initialSortModel = [], ...rest }: Props & DataGridProProps) {
  const apiRef = useGridApiRef();
  const theme = useTheme();

  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const handleCellClick = React.useCallback((params: GridCellParams) => {
    if (params.isEditable)
      setCellModesModel((prevModel) => ({
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      }));
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel: any) => {
    setCellModesModel(newModel);
  }, []);

  const [sortModel, setSortModel] = useState<GridSortModel>(initialSortModel);
  const [filterPanelAnchor, setFilterPanelAnchor] = useState<HTMLElement | null>(null);
  const [filterPanelColumn, setFilterPanelColumn] = useState<string | null>(null);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });

  return (
    <DataGridPro
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      cellModesModel={cellModesModel}
      onCellClick={handleCellClick}
      onCellModesModelChange={handleCellModesModelChange}
      disableColumnMenu
      disableRowSelectionOnClick
      disableColumnResize
      columnHeaderHeight={80}
      slots={{
        columnHeaders: ColumnHeaders,
        columnSortedAscendingIcon: SortedAscendingIcon,
        columnSortedDescendingIcon: SortedDescendingIcon,
        columnUnsortedIcon: SortedDescendingIcon,
        columnHeaderFilterIconButton: FilterButton,
        filterPanel: FilterPanel,
      }}
      slotProps={{
        panel: {
          anchorEl: filterPanelAnchor,
        },
        columnHeaderFilterIconButton: {
          setAnchor: setFilterPanelAnchor,
          setField: setFilterPanelColumn,
        },
        filterPanel: {
          filterModel,
          setFilterModel,
          column: filterPanelColumn as string,
          columnType: columns.find((x) => x.field === filterPanelColumn)?.type,
        },
      }}
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
      filterModel={filterModel}
      sx={{
        '& .MuiDataGrid-withBorderColor': {
          borderColor: theme.palette.divider,
        },
        '& .MuiDataGrid-cellContent': {
          color: theme.palette.text.secondary,
        },
        '& .MuiDataGrid-row:hover, .Mui-selected': {
          backgroundColor: 'white !important',
        },
        '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
          outlineColor: 'white',
          outline: 0,
        },
        '& .MuiDataGrid-cell--editing': {
          outline: '0 !important',
          boxShadow: 'none !important',
          bgcolor: 'white !important',
        },
        '& .MuiDataGrid-editInputCell > input': {
          ...theme.typography.body1,
          padding: '0 9px !important',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'normal',
          lineHeight: '1.2',
          maxHeight: '50px',
        },
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
          justifyContent: 'inherit',
        },
      }}
      {...rest}
    />
  );
}

export default CustomDataGrid;

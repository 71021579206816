import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';

import {
  CampaignDevice, DashboardType,
  IAggregatedStats,
  ICampaignOfferTrend,
  ICampaignOrOffer,
  IChartPoint, IGrossChartPoint,
  IGrossRow,
  IIncrementalRow
} from '../types/global';
import { IProfileOrganization } from '../types/users';
import { makeFilterId } from '../utils/filters';

import { API_URL } from './config';

type RawCampaignTableRow = {
  offer?: string;
  keywords: string[];
  campaign: string;
  domain: number;
  device?: CampaignDevice;
  starts: string;
  ends: string;
  products: ('cc' | 'cs' | 'rules')[];
  offerAttractiveness: number;
  targetedVisits: number;
  carts: number;
  cartCreationRate: number;
  purchases: number;
  purchaseRate: number;
  aov: number;
  offers: number;
  shoppers: number;
  visits: number;
};

interface IRawIncrementalRow extends RawCampaignTableRow {
  testGroup: 'control' | 'treatment';
  incrementalRevenue: number;
  conversionChange: number;
  cartCreationChange: number;
  cartCreationRateDiff: number;
  purchaseRateDiff: number;
}

interface IRawGrossRow extends RawCampaignTableRow {}

export const toPercentage = <T extends number | undefined>(x: T) => typeof x === 'number' ? x * 100 : x;

const getDomainName = (organizations: IProfileOrganization[], domainId: number) => (
  organizations.flatMap((x) => x.domains)
    .find((x) => x.id === domainId)!.name
);

type GetKPIOptions = { domain: number, startDate: string, endDate: string } | { filter: ICampaignOrOffer[] };
type GetKPI = {
  (options: GetKPIOptions & { type: 'incremental' }, organizations: IProfileOrganization[]): Promise<AxiosResponse<IIncrementalRow[]>>;
  (options: GetKPIOptions & { type: 'gross' }, organizations: IProfileOrganization[]): Promise<AxiosResponse<IGrossRow[]>>;
}
export const getKPI: GetKPI = async (
  options: GetKPIOptions & { type: 'incremental' | 'gross' },
  organizations: IProfileOrganization[],
): Promise<AxiosResponse<any>> => {
  const response = await axios.get(`${API_URL}/kpi/tableView`, {
    params: 'filter' in options ? {
      filter: JSON.stringify(options.filter),
    } : {
      domain: options.domain,
      start: options.startDate,
      end: options.endDate,
    },
  });
  type RawRow = IRawIncrementalRow | IRawGrossRow;
  type RawRowWithPath = RawRow & { path: string[] };
  const { data }: { data: RawRow[] } = response;
  const withDomains: RawRow[] = _.flatMap(
    _.map(
      _.groupBy(data, (x) => x.campaign),
      (x) => _.groupBy(x, (y) => y.domain),
    ),
    (domains) => _.flatMap(domains, (rows) => (
      rows.map((x) => Object.values(domains).length > 1 ? {
        ...x,
        campaign: `${x.campaign} / ${getDomainName(organizations, x.domain)}`,
      } : x)
    )),
  );
  const withPathAndPercentage: RawRowWithPath[] = withDomains.map((x) => ({
    ...(options.type === 'incremental' ? x : _.omit(x, 'incrementalRevenue')),
    path: [
      x.campaign,
      ...(x.offer ? [x.offer] : []),
      ...(x.device ? [x.device] : []),
    ],
    offerAttractiveness: toPercentage(x.offerAttractiveness),
    targetedVisits: toPercentage(x.targetedVisits),
    cartCreationRate: toPercentage(x.cartCreationRate),
    purchaseRate: toPercentage(x.purchaseRate),
    ...('testGroup' in x ? {
      cartCreationRateDiff: toPercentage(x.cartCreationRateDiff),
      cartCreationChange: toPercentage(x.cartCreationChange),
      purchaseRateDiff: toPercentage(x.purchaseRateDiff),
      conversionChange: toPercentage(x.conversionChange),
    } : {}),
  }));
  const withCreationRemoved: RawRowWithPath[] = withPathAndPercentage.map((x) => ({
    ...x,
    ...(x.products.includes('cs') ? {
      cartCreationRate: undefined,
      cartCreationRateDiff: undefined,
      cartCreationChange: undefined,
    } : {}),
  }));
  const withRevenueFields: (
    RawRowWithPath & { grossRevenue: number, revenuePerVisit: number, revenuePerShopper: number }
  )[] = withCreationRemoved.map((x) => {
    const grossRevenue = x.aov * x.purchases;
    return {
      ...x,
      grossRevenue,
      revenuePerVisit: grossRevenue / x.visits,
      revenuePerShopper: grossRevenue / x.shoppers,
    };
  });
  const rowsIsIncremental = (rows: (IRawIncrementalRow | IRawGrossRow)[]): rows is IRawIncrementalRow[] => options.type === 'incremental';
  if (rowsIsIncremental(withRevenueFields)) {
    const grouped = _.groupBy(withRevenueFields, (x) => x.path.join('___'));
    const newData: IIncrementalRow[] = _.compact(_.map(grouped, (x) => {
      const control = x.find((y) => y.testGroup === 'control');
      const treatment = x.find((y) => y.testGroup === 'treatment');
      const rpvDiff = (control?.revenuePerVisit && treatment?.revenuePerVisit)
        ? treatment.revenuePerVisit - control.revenuePerVisit : undefined;
      const rpvChange = (control?.revenuePerVisit && treatment?.revenuePerVisit)
        ? (treatment.revenuePerVisit / control.revenuePerVisit - 1) * 100 : undefined;
      const rpsDiff = (control?.revenuePerShopper && treatment?.revenuePerShopper)
        ? treatment.revenuePerShopper - control.revenuePerShopper : undefined;
      const rpsChange = (control?.revenuePerShopper && treatment?.revenuePerShopper)
        ? (treatment.revenuePerShopper / control.revenuePerShopper - 1) * 100 : undefined;
      return {
        ...(treatment || x[0]),
        id: `${makeFilterId()}`,
        products: x[0].products.map((product) => product === 'rules' ? 'rule' : product),
        testGroup: ['control', 'treatment'],
        targetedVisits: [control?.targetedVisits, treatment?.targetedVisits],
        offers: [control?.offers, treatment?.offers],
        carts: [control?.carts, treatment?.carts],
        cartCreationRate: [control?.cartCreationRate, treatment?.cartCreationRate],
        purchases: [control?.purchases, treatment?.purchases],
        purchaseRate: [control?.purchaseRate, treatment?.purchaseRate],
        aov: [control?.aov, treatment?.aov],
        grossRevenue: [control?.grossRevenue, treatment?.grossRevenue],
        revenuePerVisit: [control?.revenuePerVisit, treatment?.revenuePerVisit],
        revenuePerShopper: [control?.revenuePerShopper, treatment?.revenuePerShopper],
        rpvDiff,
        rpvChange,
        rpsDiff,
        rpsChange,
      };
    }));
    return {
      ...response,
      data: newData,
    };
  }
  const rowsIsGross = (rows: (IRawIncrementalRow | IRawGrossRow)[]): rows is (IRawGrossRow & { path: string[] })[] => options.type === 'gross';
  if (rowsIsGross(withRevenueFields)) {
    const newData: IGrossRow[] = withRevenueFields.filter((x) =>
       (x as any).testGroup !== 'control'
    ).map((x) => ({
      ...x,
      id: `${makeFilterId()}`,
      products: x.products.map((product) => product === 'rules' ? 'rule' : product),
    }));
    return {
      ...response,
      data: newData,
    };
  }
  return response;
};

interface IRawChartPoint {
  datePoint: string;
  campaign: string;
  domain: number;
  offer?: string;
  testGroup: 'control' | 'treatment';
  offers: number;
  carts: number;
  purchases: number;
  purchasedValue: number;
  purchaseRate: number;
  aov: number;
  purchaseRateChange: number;
  incrementalRevenue: number;
  cartCreationChange: number;
  visits: number;
  shoppers: number;
  cartCreationRate: number;
  rpshopper: number;
  rpvisit: number;
}

export const getChartData = async (
  options: GetKPIOptions & { type: 'incremental' | 'gross' },
  organizations: IProfileOrganization[],
): Promise<AxiosResponse<ICampaignOfferTrend[]>> => {
  const response = await axios.get(`${API_URL}/kpi/charts`, {
    params: 'filter' in options ? {
      filter: JSON.stringify(options.filter),
    } : {
      domain: options.domain,
      start: options.startDate,
      end: options.endDate,
    },
  });
  const { data }: { data: IRawChartPoint[] } = response;
  const withDomains: IRawChartPoint[] = _.flatMap(
    _.map(
      _.groupBy(data, (x) => x.campaign),
      (x) => _.groupBy(x, (y) => y.domain),
    ),
    (domains) => _.flatMap(domains, (points) => (
      points.map((x) => Object.values(domains).length > 1 ? {
        ...x,
        campaign: `${x.campaign} / ${getDomainName(organizations, x.domain)}`,
      } : x)
    )),
  );
  const withPercentage: IRawChartPoint[] = withDomains.map((x) => ({
    ...x,
    purchaseRate: toPercentage(x.purchaseRate),
    cartCreationChange: toPercentage(x.cartCreationChange),
    cartCreationRate: toPercentage(x.cartCreationRate),
  }));
  if (options.type === 'incremental') {
    const grouped = _.groupBy(withPercentage, (x) => x.offer ? `OFFER___${x.offer}` : x.campaign);
    const newData = _.map(grouped, (x): ICampaignOfferTrend => {
      const groupedPoints = _.groupBy(x, (y) => y.datePoint);
      const points = _.map(groupedPoints, (datePoints): IChartPoint => {
        const control = datePoints.find((y) => y.testGroup === 'control');
        const treatment = datePoints.find((y) => y.testGroup === 'treatment');
        const grossRevenue = (treatment?.aov || 0) * (treatment?.purchases || 0);
        return {
          date: datePoints[0].datePoint,
          purchaseRate: [control?.purchaseRate, treatment?.purchaseRate],
          newNetRevenue: treatment?.incrementalRevenue,
          cartCreationChange: treatment?.cartCreationChange,
          purchaseRateChange: treatment?.purchaseRateChange,
          aov: [control?.aov, treatment?.aov],
          offers: [control?.offers, treatment?.offers],
          carts: [control?.carts, treatment?.carts],
          purchases: [control?.purchases, treatment?.purchases],
          purchasedValue: [control?.purchasedValue, treatment?.purchasedValue],
          grossRevenue,
          revenuePerVisit: [control?.rpvisit, treatment?.rpvisit],
          revenuePerShopper: [control?.rpshopper, treatment?.rpshopper],
          visits: [control?.visits, treatment?.visits],
          shoppers: [control?.shoppers, treatment?.shoppers],
        };
      });
      return {
        campaign: x[0].campaign,
        ...(x[0].offer ? { offer: x[0].offer } : {}),
        points,
      };
    });
    return {
      ...response,
      data: newData,
    };
  }
  const grouped = _.groupBy(withPercentage.filter((x) =>
    (x as any).testGroup !== 'control'
  ), (x) => x.offer ? `OFFER___${x.offer}` : x.campaign);
  const newData = _.map(grouped, (x): ICampaignOfferTrend => {
    const points = _.uniqBy(_.map(x, (datePoint): IGrossChartPoint => {
      const grossRevenue = (datePoint.aov || 0) * (datePoint.purchases || 0);
      return {
        date: datePoint.datePoint,
        ...datePoint,
        grossRevenue,
        revenuePerVisit: datePoint.rpvisit,
        revenuePerShopper: datePoint.rpshopper,
      };
    }), (y) => y.date);
    return {
      campaign: x[0].campaign,
      ...(x[0].offer ? { offer: x[0].offer } : {}),
      points,
    };
  });
  return {
    ...response,
    data: newData,
  };
}

export const getCampaignsAndOffers = async (organization: number): Promise<AxiosResponse<ICampaignOrOffer[]>> => {
  const response = await axios.get<ICampaignOrOffer[]>(`${API_URL}/kpi/campaigns-and-offers`, { params: { organization } });
  const { data } = response;
  return {
    ...response,
    data: [...data]
      // Temporary workaround for a backend bug
      .filter((x) => x.campaign !== null)
      .sort((a, b) => {
        if (!('offer' in a) && 'offer' in b) {
          return -1;
        }
        if ('offer' in a && !('offer' in b)) {
          return 1;
        }
        if (a.offer && b.offer) {
          return a.offer.localeCompare(b.offer);
        }
        return a.campaign.localeCompare(b.campaign);
      })
  };
};

export const getAggregatedStats = async (domain: number, dashboardType: DashboardType): Promise<AxiosResponse<IAggregatedStats>> => {
  const response = await axios.get(`${API_URL}/kpi/lifetime-aggr-stats`, { params: { domain } });
  const { data } = response;
  return {
    ...response,
    data: dashboardType === DashboardType.Incremental ? {
      incrementalRevenue: data.incrementalRevenue,
    } : {
      grossRevenue: data.grossRevenue,
    } as any,
  };
};

import React from 'react';
import { Outlet } from 'react-router-dom';
import { ReactComponent as MetricalLogoWhite } from 'assets/metrical-logo-white.svg';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import Footer from '../Footer';

export default function AuthLayout() {
  const theme = useTheme();
  return (
    <Box
      data-testid="auth-layout"
      sx={{
        backgroundColor: theme.palette.secondary.lighter,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '216px',
          backgroundColor: '#1F378D',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          paddingBottom: '20px',
        }}
      >
        <Link
          href="https://metric.al/"
        >
          <Box sx={{ width: '100%', maxWidth: '290px' }} component={MetricalLogoWhite} />
        </Link>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '364px',
          padding: '40px 16px',
          margin: '0 auto',
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
}

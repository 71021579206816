import React from 'react';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';

export default function Footer() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.secondary.dark,
        fontSize: '12px',
        padding: '4px',
        '& > :not(:last-child)::after': {
          content: '"|"',
          display: 'inline',
          margin: '0 6px',
        },
      }}
    >
      <Typography
        sx={{
          fontSize: 'inherit',
        }}
      >
        © {dayjs().format('YYYY')} Metrical
      </Typography>
      <Typography
        component="a"
        href="https://metric.al/metrical-terms-msa"
        target="_blank"
        sx={{
          fontSize: 'inherit',
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        Terms of Service
      </Typography>
      <Typography
        component="a"
        href="https://metric.al/privacy-policy"
        target="_blank"
        sx={{
          fontSize: 'inherit',
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        Privacy Policy
      </Typography>
    </Box>
  );
}

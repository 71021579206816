import React, { Dispatch, SetStateAction } from 'react';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { ColumnHeaderFilterIconButtonPropsOverrides } from '@mui/x-data-grid/models/gridSlotsComponentsProps';
import {
  ColumnHeaderFilterIconButtonProps,
  gridColumnLookupSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro';

export default function FilterButton(
  props: ColumnHeaderFilterIconButtonProps & ColumnHeaderFilterIconButtonPropsOverrides
) {
  const { field, counter, setAnchor, setField } = props;
  const apiRef = useGridApiContext();
  const columnDefinitions = gridColumnLookupSelector(apiRef);
  if (columnDefinitions[field].filterable === false) {
    return null;
  }
  return (
    <IconButton
      size="small"
      sx={{ order: 3, position: 'relative' }}
      onClick={(event) => {
        event.stopPropagation();
        setAnchor(event.currentTarget as HTMLElement);
        setField(field);
        apiRef.current.showFilterPanel(field);
      }}
      aria-label="Filter"
    >
      <FilterIcon />
      {counter && (
        <Box
          sx={{
            position: 'absolute',
            top: '5px',
            right: '3px',
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            backgroundColor: '#8BD267',
          }}
        />
      )}
    </IconButton>
  );
}

declare module '@mui/x-data-grid/models/gridSlotsComponentsProps' {
  interface ColumnHeaderFilterIconButtonPropsOverrides {
    setAnchor: Dispatch<SetStateAction<HTMLElement | null>>;
    setField: Dispatch<SetStateAction<string | null>>;
  }
}

import React from 'react';

import WarningAmber from '@mui/icons-material/WarningAmber';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';

const StyledContent = styled(DialogContent)({
  display: 'flex',
  gap: 12,
  color: 'white',
  padding: 0,
});

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
  content?: React.ReactNode;
  actionButtonLabels?: {
    confirm?: string;
    cancel?: string;
  };
};

function CustomModal({ open, content, onConfirm, onCancel, onClose, actionButtonLabels }: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(69, 89, 167, 0.4)',
          },
        },
      }}
      sx={{
        '& .MuiPaper-root': {
          boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12)',
          maxWidth: 500,
          backgroundColor: '#EF6C00',
          color: 'white',
          padding: '6px 16px',
          borderRadius: '4px',
        },
      }}
    >
      <StyledContent>
        <WarningAmber sx={{ fontSize: 36 }} />
        <DialogContentText
          component="div"
          variant="h6"
          color="white"
          paddingY={1}
          id="alert-dialog-description"
        >
          {content}
        </DialogContentText>
      </StyledContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel ?? onClose}>
          <Typography variant="subtitle2" color="white" textTransform="uppercase">
            {actionButtonLabels?.cancel ?? 'cancel'}
          </Typography>
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm ?? onClose}>
          <Typography variant="subtitle2" color="white" textTransform="uppercase">
            {actionButtonLabels?.confirm ?? 'confirm'}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { CustomModal };

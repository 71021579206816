import React from 'react';
import soleilExtrabold from 'assets/fonts/Soleil-Extrabold.woff2';
import soleilRegular from 'assets/fonts/Soleil-Regular.woff2';

export default function FontFace() {
  return (
    <style>
      {`
      @font-face {
        font-family: 'Soleil';
        src: url("${soleilRegular}") format("woff2");
        font-weight: 400;
        font-display: auto;
        font-style: normal;
        font-stretch: normal;
      }
      @font-face {
        font-family: 'Soleil';
        src: url("${soleilExtrabold}") format("woff2");
        font-weight: 800;
        font-display: auto;
        font-style: normal;
        font-stretch: normal;
      }
      `}
    </style>
  );
}

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as MetricalLogoWhite } from 'assets/metrical-logo-white.svg';

import Box, { BoxProps } from '@mui/material/Box';

interface ProjectLogoProps extends BoxProps {
  disabledLink?: boolean;
}

export default function ProjectLogo({ disabledLink = false }: ProjectLogoProps) {
  const logo = (
    <Box data-testid="project-logo" sx={{ width: 94, height: '100%' }} component={MetricalLogoWhite} />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <Box component={RouterLink} to="/dashboard" sx={{ display: 'flex' }}>{logo}</Box>;
}

import { supabase } from 'auth';
import axios, { AxiosResponse } from 'axios';

export const API_URL = process.env.REACT_APP_API_URL as string;

axios.interceptors.request.use(
  async (config) => {
    const allowedOrigins = [API_URL].map((x) => new URL(x).origin);
    const { origin } = new URL(config.url as string);

    await supabase.auth.getSession().then(({ data: { session } }) => {
      if (session && allowedOrigins.includes(origin)) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${session.access_token}`;
      }
    });

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (res: AxiosResponse) => res,
  (error: any) => {
    if (error.message && !error.response) {
      return false;
    }
    throw error;
  }
);

export default axios;

import React, { useEffect, useState } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { logoUploadAPI } from 'apis/domains';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { IUploaded } from '../../types/global';
import resizeImage from '../../utils/resizeImage';

interface InputTextProps {
  /**
   * Presenting as a placeholder for accessibility of an input.
   */
  placeholder?: string;

  /**
   * Presenting as the input label.
   */
  label?: string;

  /**
   * Form Controller
   */
  formControl?: ControllerRenderProps<any, any>;

  value?: string;

  /**
   * A function for handling an input change value.
   * @param event
   */
  handleChange?: (value: string) => void;
}

export function FormInput({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

function TextFieldBase({
  placeholder = '',
  testID = '',
  formControl,
  value,
  handleChange = () => {},
  ...props
}: InputTextProps & TextFieldProps & { testID?: string }) {
  return (
    <TextField
      fullWidth
      placeholder={placeholder}
      data-testid={testID}
      value={formControl ? formControl.value : value}
      onChange={formControl ? formControl.onChange : (event) => handleChange(event.target.value)}
      {...props}
      {...formControl}
    />
  );
}

type ImageUploaderProps = {
  name: string;
  logo: IUploaded | null;
  control: any;
  setValue: (value: IUploaded | null) => void;
};

function ImageUploader({ name, logo, setValue, control }: ImageUploaderProps) {
  const [image, setImage] = useState<IUploaded | null>(logo || null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = async (files: FileList | null) => {
    if (files && files.length > 0) {
      const imageFile = await resizeImage(files[0]);
      const formData = new FormData();
      formData.append('file', imageFile);

      setIsLoading(true);
      const response = await logoUploadAPI(formData);
      setImage(response.data);
      setIsLoading(false);
      return response.data;
    }
    return null;
  };

  useEffect(() => {
    setValue(image);
  }, [image]);

  useEffect(() => {
    setImage(logo || null);
  }, [logo]);

  return (
    <Card
      component="label"
      sx={{
        height: 100,
        maxWidth: 200,
        minWidth: 200,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {isLoading ? (
        <CardContent>
          <CircularProgress sx={{ marginX: 5 }} />
        </CardContent>
      ) : (
        <>
          {image ? (
            <CardMedia
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
              }}
            >
              <Box
                component="img"
                src={image.uploadedUrl}
                alt="Company logo"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </CardMedia>
          ) : (
            <CardContent>
              <Typography variant="body2">Click here to add domain logo</Typography>
            </CardContent>
          )}
        </>
      )}

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <input
            type="file"
            onChange={async (e) => {
              const url = await handleFileUpload(e.target.files);
              if (url) {
                field.onChange({
                  target: {
                    value: url,
                  },
                });
              }
            }}
            accept=".jpg,.png"
            ref={field.ref}
            hidden
          />
        )}
      />
      <IconButton
        sx={{
          padding: 0,
          position: 'absolute',
          top: 0,
          right: 0,
        }}
        onClick={() => setImage(null)}
      >
        <CloseIcon />
      </IconButton>
    </Card>
  );
}

FormInput.TextFieldBase = TextFieldBase;
FormInput.ImageUploader = ImageUploader;

import React, { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';

interface LabeledNumberProps extends React.HTMLAttributes<HTMLDivElement> {
  number: string;
  label: string;
  width: number;
}

// eslint-disable-next-line prefer-arrow-callback
export default forwardRef(function LabeledNumber({ number, label, width, ...rest }: LabeledNumberProps, ref) {
  const theme = useTheme();
  return (
    <Box
      ref={ref}
      sx={{
        color: 'inherit',
        width,
        [theme.breakpoints.down('lg')]: {
          width: 'auto',
          paddingRight: '8px',
        },
      }}
      {...rest}
    >
      <Typography
        sx={{
          fontWeight: 800,
          fontSize: '16px',
          lineHeight: 1.27,
          whiteSpace: 'nowrap',
        }}
      >
        {number}
      </Typography>
      <Typography
        sx={{
          fontSize: 10,
          lineHeight: 1.27,
          fontVariant: 'all-small-caps',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
});

import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import MainLayout from 'layouts/main';
import ManageOrganizationPage from 'pages/accounts/Organization';
import UserRolesPage from 'pages/accounts/Roles';
import Register from 'pages/auth/Register';
import CustomerOrganizationPage from 'pages/customer/CustomerOrganization';
import Dashboard from 'pages/Dashboard';
import Profile from 'pages/Profile';

import AuthLayout from '../layouts/auth';
import AccessPending from '../pages/auth/AccessPending';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Login from '../pages/auth/Login';
import ResetPassword from '../pages/auth/ResetPassword';

import PATH_NAMES from './paths';

export default function Router() {
  return useRoutes([
    {
      path: PATH_NAMES.dashboard.root,
      element: <MainLayout isDashboard />,
      children: [{ element: <Dashboard />, index: true }],
    },
    {
      path: PATH_NAMES.accounts.root,
      element: <MainLayout />,
      children: [
        {
          element: <Navigate to={PATH_NAMES.accounts.roles} replace />,
          index: true,
        },
        { path: PATH_NAMES.accounts.roles, element: <UserRolesPage /> },
        {
          path: PATH_NAMES.accounts.organization,
          element: <ManageOrganizationPage />,
        },
      ],
    },
    {
      path: PATH_NAMES.customer.root,
      element: <MainLayout />,
      children: [
        {
          element: <Navigate to={PATH_NAMES.customer.organization} replace />,
          index: true,
        },
        {
          path: PATH_NAMES.customer.organization,
          element: <CustomerOrganizationPage />,
        },
      ],
    },
    {
      path: PATH_NAMES.profile.root,
      element: <MainLayout />,
      children: [
        // {
        //   element: <Navigate to="" replace />,
        //   index: true,
        // },
        {
          path: '',
          element: <Profile />,
        },
      ],
    },
    {
      path: PATH_NAMES.auth.root,
      element: <AuthLayout />,
      children: [
        {
          element: <Navigate to={PATH_NAMES.auth.login} replace />,
          index: true,
        },
        { path: PATH_NAMES.auth.login, element: <Login /> },
        { path: PATH_NAMES.auth.register, element: <Register /> },
        { path: PATH_NAMES.auth.forgotPassword, element: <ForgotPassword /> },
        { path: PATH_NAMES.auth.resetPassword, element: <ResetPassword /> },
        { path: PATH_NAMES.auth.accessPending, element: <AccessPending /> },
      ],
    },
    { path: '*', element: <Navigate to="/dashboard" replace /> },
  ]);
}

import React, { useContext, useEffect, useState } from 'react';
import { FormContainer, SelectElement, TextFieldElement, useForm } from 'react-hook-form-mui';
import { NumericFormat } from 'react-number-format';
import {
  CURRENCY_DECIMAL,
  CURRENCY_GROUPING,
  DATE_FORMATS,
  DAYS_OF_WEEK,
  NUMBER_FORMATS,
  SUBSCRIPTION_DATE,
} from 'constants/profile';
import dayjs from 'dayjs';
import AvatarUploader from 'modules/screens/profile/AvatarUploader';

import { styled, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { updateUserProfile } from '../apis/users';
import { SessionContext } from '../auth';
import useAsyncEffect from '../hooks/useAsyncEffect';
import { IUploaded } from '../types/global';
import { DateFormat, DecimalSymbol, GroupingSymbol, IProfile } from '../types/users';

const inputProps = {
  sx: {
    backgroundColor: 'transparent',
    '& .MuiSelect-outlined': {
      padding: '5px 40px 5px 16px !important',
      minHeight: '1rem !important',
      lineHeight: '16px',
    },
  },
};

const CustomStack = styled(Stack)({
  gap: 60,
  marginTop: 60,
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: 350,
});

const StyledTypography = styled(Typography)({
  lineHeight: '20px',
});

const PreviewBox = styled(Stack)({
  marginLeft: 16,
  width: 130,
  height: 52,
  backgroundColor: 'white',
});

const CurrencyElement = styled(FormControl)({
  marginTop: 10,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledSelectFormControl = styled(FormControl)({
  gap: '4px',
});

const StyledSelect = styled(SelectElement)({
  width: 'fit-content',
  border: '1px solid #4559A7',
  boxSizing: 'border-box',
  backgroundColor: 'transparent',
  borderRadius: 4,
});

type ProfileForm = Omit<IProfile, 'avatarUrl'>;

export default function Profile() {
  const theme = useTheme();
  const { session, profile, setProfile } = useContext(SessionContext);
  const formContext = useForm<ProfileForm>();
  const [ready, setReady] = useState(false);
  const { handleSubmit, setValue, reset: formReset, formState } = formContext;
  const [updateResult, setUpdateResult] = useState<null | 'success' | 'failure'>(null);

  const [initialValues, setInitialValues] = useState<IProfile | null>(null);
  const [avatar, setAvatar] = useState<IUploaded | null>(null);
  const [groupingSymbol, setGroupingSymbol] = useState<GroupingSymbol>('none');
  const [decimalSymbol, setDecimalSymbol] = useState<DecimalSymbol>('period');
  const [dateFormat, setDateFormat] = useState<DateFormat>('month-first');

  const resetForm = (data: IProfile) => {
    formReset(data);
    setAvatar((data.avatarUrl && data.avatarId) ? { uploadedId: data.avatarId, uploadedUrl: data.avatarUrl } : null);
    setGroupingSymbol(data.currencyGrouping);
    setDecimalSymbol(data.currencyDecimal);
    setDateFormat(data.dateFormat);
  };

  useAsyncEffect(async () => {
    if (profile) {
      setInitialValues(profile);
      resetForm(profile);
      setReady(true);
    }
  }, [profile]);

  useEffect(() => {
    setValue('currencyDecimal', decimalSymbol);
    setValue('currencyGrouping', groupingSymbol);
    if (decimalSymbol === 'comma' && groupingSymbol === 'comma') {
      setGroupingSymbol('none');
    }
  }, [groupingSymbol, decimalSymbol]);

  useEffect(() => {
    setValue('dateFormat', dateFormat);
  }, [dateFormat]);

  useEffect(() => {
    if (formState.isDirty) {
      setUpdateResult(null);
    }
  }, [formState.isDirty]);

  if (!ready || !session || !initialValues) {
    return null;
  }

  const onSubmit = async (formValue: ProfileForm) => {
    setUpdateResult(null);
    try {
      const newData = { ...formValue, avatarId: avatar?.uploadedId ?? null };
      await updateUserProfile(newData);
      const newProfile = { ...newData, avatarUrl: avatar?.uploadedUrl ?? null };
      setInitialValues(newProfile);
      resetForm(newProfile);
      setProfile(newProfile);
      setUpdateResult('success');
    } catch {
      setUpdateResult('failure');
    }
  };

  return (
    <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
      <CustomStack sx={{ marginBottom: 4 }}>
        <Stack direction="row" sx={{ position: 'relative' }}>
          <AvatarUploader image={avatar} setImage={setAvatar} />

          <Stack spacing={1.5} flexGrow={1}>
            <TextFieldElement name="firstName" label="First name" sx={{ maxWidth: '204px' }} />
            <TextFieldElement name="lastName" label="Last name" sx={{ maxWidth: '204px' }} />
            <Typography variant="body2">{session.user.email}</Typography>
          </Stack>
        </Stack>

        <StyledSelectFormControl>
          <StyledTypography variant="subtitle2" textTransform="uppercase">
            Receive email reports
          </StyledTypography>
          <StyledSelect
            name="emailFrequency"
            options={SUBSCRIPTION_DATE}
            InputProps={inputProps}
            required
          />
        </StyledSelectFormControl>

        <Stack direction="row">
          <Stack sx={{ flex: 1, gap: 2 }}>
            <StyledSelectFormControl>
              <StyledTypography variant="subtitle2" textTransform="uppercase">
                Date format
              </StyledTypography>
              <StyledSelect
                name="dateFormat"
                options={DATE_FORMATS}
                onChange={(e) => setDateFormat(e)}
                InputProps={inputProps}
              />
            </StyledSelectFormControl>

            <StyledSelectFormControl>
              <StyledTypography variant="subtitle2" textTransform="uppercase">
                First day of week
              </StyledTypography>
              <StyledSelect name="firstWeekday" options={DAYS_OF_WEEK} InputProps={inputProps} />
            </StyledSelectFormControl>
          </Stack>

          <PreviewBox>
            <Typography variant="subtitle1" color={theme.palette.primary.dark} margin="auto">
              {dayjs('2023-05-29').format(DATE_FORMATS.find(({ id }) => id === dateFormat)?.value)}
            </Typography>
          </PreviewBox>
        </Stack>

        <Stack direction="row">
          <FormControl sx={{ flex: 1 }}>
            <Typography variant="subtitle2" lineHeight="1rem" textTransform="uppercase">
              Number format
            </Typography>

            <CurrencyElement>
              <Typography variant="body2">Grouping</Typography>
              <StyledSelect
                data-testid="grouping"
                name="currencyGrouping"
                options={CURRENCY_GROUPING}
                InputProps={inputProps}
                onChange={(e) => setGroupingSymbol(e)}
              />
            </CurrencyElement>

            <CurrencyElement>
              <Typography variant="body2">Decimal</Typography>
              <StyledSelect
                data-testid="decimal"
                name="currencyDecimal"
                options={CURRENCY_DECIMAL}
                InputProps={inputProps}
                onChange={(e) => setDecimalSymbol(e)}
              />
            </CurrencyElement>
          </FormControl>

          <PreviewBox>
            <Typography variant="subtitle1" color={theme.palette.primary.dark} margin="auto">
              <NumericFormat
                value={12782.01}
                displayType="text"
                thousandSeparator={
                  groupingSymbol === decimalSymbol ? '' : NUMBER_FORMATS.grouping[groupingSymbol]
                }
                decimalSeparator={NUMBER_FORMATS.decimal[decimalSymbol]}
                suffix=" USD"
              />
            </Typography>
          </PreviewBox>
        </Stack>

        <Stack>
          <Stack direction="row" marginLeft="auto" gap={2}>
            <Button
              variant="text"
              onClick={() => resetForm(initialValues)}
            >
              Discard changes
            </Button>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </Stack>
          {updateResult && (
            <Typography variant="body2" sx={{ marginTop: 1, textAlign: 'right', fontSize: '12px' }}>
              {updateResult === 'success' ? 'Changes have been saved.' : 'Changes could not be saved.'}
            </Typography>
          )}
        </Stack>
      </CustomStack>
    </FormContainer>
  );
}

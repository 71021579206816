import dayjs from 'dayjs';

import { getGridStringOperators, GridFilterOperator } from '@mui/x-data-grid-pro';


const defaultStringOperators = getGridStringOperators();
const beginsWithOperator = defaultStringOperators.find((x) => x.value === 'startsWith') as GridFilterOperator;
const endsWithOperator = defaultStringOperators.find((x) => x.value === 'endsWith') as GridFilterOperator;
const containsOperator = defaultStringOperators.find((x) => x.value === 'contains') as GridFilterOperator;

export const stringOperators: GridFilterOperator[] = [
  {
    value: 'select',
    label: 'Select',
    getApplyFilterFn: (filterItem) => (params) => filterItem.value.includes(params.value),
  },
  { label: 'Begins With', ...beginsWithOperator },
  {
    value: 'notBeginsWith',
    label: 'Does not Begin With',
    getApplyFilterFn: (filterItem, column) => (params) => (
      !beginsWithOperator.getApplyFilterFn(filterItem, column)?.(params)
    ),
  },
  { label: 'Ends With', ...endsWithOperator },
  {
    value: 'notEndsWith',
    label: 'Does not End With',
    getApplyFilterFn: (filterItem, column) => (params) => (
      !endsWithOperator.getApplyFilterFn(filterItem, column)?.(params)
    ),
  },
  { label: 'Contains', ...containsOperator },
  {
    value: 'notContains',
    label: 'Does not Contain',
    getApplyFilterFn: (filterItem, column) => (params) => (
      !containsOperator.getApplyFilterFn(filterItem, column)?.(params)
    ),
  },
];

export const dateOperators: GridFilterOperator[] = [
  {
    value: 'is',
    label: 'Select Date',
    getApplyFilterFn: (filterItem) => (params) => (
      dayjs(params.value).isSame(filterItem.value, 'day')
    ),
  },
  {
    value: 'before',
    label: 'Before',
    getApplyFilterFn: (filterItem) => (params) => (
      dayjs(params.value).isBefore(filterItem.value)
    ),
  },
  {
    value: 'after',
    label: 'After',
    getApplyFilterFn: (filterItem) => (params) => (
      dayjs(params.value).isAfter(filterItem.value)
    ),
  },
  {
    value: 'between',
    label: 'Between',
    getApplyFilterFn: (filterItem) => (params) => (
      dayjs(params.value).isSameOrAfter(filterItem.value[0]) && dayjs(params.value).isSameOrBefore(filterItem.value[1])
    ),
  },
  {
    value: 'today',
    label: 'Today',
    getApplyFilterFn: () => (params) => (
      dayjs(params.value).isSame(dayjs(), 'day')
    ),
  },
  {
    value: 'yesterday',
    label: 'Yesterday',
    getApplyFilterFn: () => (params) => (
      dayjs(params.value).isSame(dayjs().subtract(1, 'day'), 'day')
    ),
  },
  {
    value: 'thisWeek',
    label: 'This Week',
    getApplyFilterFn: () => (params) => (
      dayjs(params.value).isSame(dayjs(), 'week')
    ),
  },
  {
    value: 'lastWeek',
    label: 'Last Week',
    getApplyFilterFn: () => (params) => (
      dayjs(params.value).isSame(dayjs().subtract(1, 'week'), 'week')
    ),
  },
  {
    value: 'thisMonth',
    label: 'This Month',
    getApplyFilterFn: () => (params) => (
      dayjs(params.value).isSame(dayjs(), 'month')
    ),
  },
  {
    value: 'lastMonth',
    label: 'Last Month',
    getApplyFilterFn: () => (params) => (
      dayjs(params.value).isSame(dayjs().subtract(1, 'month'), 'month')
    ),
  },
  {
    value: 'thisQuarter',
    label: 'This Calendar Quarter',
    getApplyFilterFn: () => (params) => {
      const startOfQuarter = dayjs().subtract(dayjs().month() % 3, 'months').startOf('month');
      const endOfQuarter = dayjs(startOfQuarter).add(2, 'months').endOf('month');
      return (
        dayjs(params.value).isAfter(startOfQuarter) && dayjs(params.value).isBefore(endOfQuarter)
      );
    },
  },
  {
    value: 'lastQuarter',
    label: 'Last Calendar Quarter',
    getApplyFilterFn: () => (params) => {
      const startOfQuarter = dayjs().subtract(3 + dayjs().month() % 3, 'months').startOf('month');
      const endOfQuarter = dayjs(startOfQuarter).add(2, 'months').endOf('month');
      return (
        dayjs(params.value).isAfter(startOfQuarter) && dayjs(params.value).isBefore(endOfQuarter)
      );
    },
  },
  {
    value: 'thisYear',
    label: 'This Calendar Year',
    getApplyFilterFn: () => (params) => (
      dayjs(params.value).isSame(dayjs(), 'year')
    ),
  },
  {
    value: 'lastYear',
    label: 'Last Calendar Year',
    getApplyFilterFn: () => (params) => (
      dayjs(params.value).isSame(dayjs().subtract(1, 'year'), 'year')
    ),
  },
];

function anyMatch(x: number | number[], matcher: (x: number) => boolean) {
  const xList = Array.isArray(x) ? x : [x];
  return xList.some((value) => matcher(value));
}

export const numberOperators: GridFilterOperator[] = [
  {
    value: 'equals',
    label: 'Equals',
    getApplyFilterFn: (filterItem) => (params) => (
      anyMatch(params.value, (x) => x === Number(filterItem.value))
    ),
  },
  {
    value: 'notEquals',
    label: 'Does not Equal',
    getApplyFilterFn: (filterItem) => (params) => (
      anyMatch(params.value, (x) => x !== Number(filterItem.value))
    ),
  },
  {
    value: 'greaterThan',
    label: 'Greater Than',
    getApplyFilterFn: (filterItem) => (params) => (
      anyMatch(params.value, (x) => x > Number(filterItem.value))
    ),
  },
  {
    value: 'greaterOrEqual',
    label: 'Greater Than or Equal To',
    getApplyFilterFn: (filterItem) => (params) => (
      anyMatch(params.value, (x) => x >= Number(filterItem.value))
    ),
  },
  {
    value: 'lessThan',
    label: 'Less Than',
    getApplyFilterFn: (filterItem) => (params) => (
      anyMatch(params.value, (x) => x < Number(filterItem.value))
    ),
  },
  {
    value: 'lessOrEqual',
    label: 'Less Than or Equal To',
    getApplyFilterFn: (filterItem) => (params) => (
      anyMatch(params.value, (x) => x <= Number(filterItem.value))
    ),
  },
  {
    value: 'between',
    label: 'Between',
    getApplyFilterFn: (filterItem) => (params) => (
      anyMatch(params.value, (x) => x > Number(filterItem.value[0]) && x < Number(filterItem.value[1]))
    ),
  },
];

export const listOperators: GridFilterOperator[] = [
  {
    value: 'select',
    label: 'Select',
    getApplyFilterFn: (filterItem) => (params) => (
      params.value.some((x: string) => filterItem.value.includes(x))
    ),
  },
  {
    value: 'contains',
    label: 'Contains',
    getApplyFilterFn: (filterItem) => (params) => (
      params.value.some((x: string) => x.includes(filterItem.value))
    ),
  },
];

export const booleanOperators: GridFilterOperator[] = [
  {
    value: 'checkbox',
    label: 'Checkbox',
    getApplyFilterFn: (filterItem) => (params) => params.value === filterItem.value,
  },
];

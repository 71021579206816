import React, { createContext, useContext } from 'react';
import { createClient, Session } from '@supabase/supabase-js';

import { fetchUserProfile } from '../apis/users';
import { IProfile } from '../types/users';

export const supabase = createClient(process.env.REACT_APP_SUPABASE_URL as string, process.env.REACT_APP_SUPABASE_KEY as string);

export interface SessionContextValue {
  session: Session | null;
  profile: IProfile | null;
  setProfile: React.Dispatch<React.SetStateAction<IProfile | null>>;
}

export const SessionContext = createContext<SessionContextValue>({ session: null, profile: null, setProfile: () => {} });

export const useUpdateProfile = () => {
  const { setProfile } = useContext(SessionContext);
  return async () => {
    const { data } = await fetchUserProfile();
    setProfile(data);
  };
};

import React, { ReactNode, useMemo } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';

import '@mui/x-data-grid-pro/themeAugmentation';

import breakpoints from './breakpoints';
import ComponentOverrides from './overrides';
import palette from './palette';
import shadows, { customShadows } from './shadows';
import typography from './typography';

// ----------------------------------------------------------------------
export enum OutlinedVariants {
  square = 'outlinedSquare',
  rectangle = 'outlindeRectangle',
}

export interface EleMuiVariantOverrides {
  [OutlinedVariants.square]: true;
  [OutlinedVariants.rectangle]: true;
}

declare module '@mui/material/Button/Button' {
  interface ButtonPropsVariantOverrides extends EleMuiVariantOverrides {}
}

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  // const { themeMode, themeDirection } = useSettings();

  const isLight = true;

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight]
  );

  const theme = createTheme(themeOptions);

  theme.components = ComponentOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}

import { Theme } from '@mui/material/styles';

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          textTransform: 'none',
        },
        sizeSmall: {
          padding: '0 16px',
        },
        sizeLarge: {
          paddingTop: '9px',
          paddingBottom: '9px',
          fontSize: '14px',
          lineHeight: '18px',
        },
        // contained
        containedPrimary: {
          borderRadius: '18px',
        },
        containedInherit: {
          color: theme.palette.grey[800],
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.primary.main}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}

import type { IOrganizationParams } from 'types/organizations';

import axios, { API_URL } from './config';

export const getOrganizationsAPI = async () => axios.get(`${API_URL}/organizations`);

export const createOrganizationAPI = async (body: IOrganizationParams) =>
  axios.post(`${API_URL}/organizations`, body);

export const updateOrganizationAPI = async (id: string | number, body: IOrganizationParams) =>
  axios.put(`${API_URL}/organizations/${id}`, body);

export const removeOrganizationAPI = async (id: number | string) =>
  axios.delete(`${API_URL}/organizations/${id}`);

import React from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { FormInput } from 'components/Forms/FormInput';

import { Clear } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Stack } from '@mui/system';

type Props = {
  searchText: string;
  setSearchText: (value: string) => void;
  onDiscard: () => void;
  disabled: boolean;
};

function TableActionBar({ searchText, setSearchText, onDiscard, disabled }: Props) {
  return (
    <Grid container alignItems="center">
      <Grid item xs={5}>
        <FormInput.TextFieldBase
          name="search"
          label=""
          value={searchText}
          variant="outlined"
          testID="search-input"
          handleChange={setSearchText}
          InputProps={{
            sx: {
              '&> input::placeholder': {
                color: 'black',
              },
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchText.length > 0 ? (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setSearchText('')}>
                  <Clear fontSize="small" />
                </IconButton>
              </InputAdornment>
            ) : null,
            autoComplete: 'search',
            inputProps: {
              'data-lpignore': true,
            },
          }}
          // Disable autocomplete
          placeholder={'Search for na\u200bme, em\u200bail, organization or domain'}
        />
      </Grid>
      <Grid item xs={7}>
        <Stack justifyContent="end" direction="row" spacing={4}>
          <Button variant="text" data-testid="discard-btn" onClick={onDiscard} disabled={disabled}>
            Discard changes
          </Button>
          <Button variant="contained" type="submit" data-testid="save-btn" disabled={disabled}>
            Save
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default TableActionBar;

import React from 'react';
import { ReactComponent as CheckboxPartial } from 'assets/icons/checkbox-partial.svg';
import { ReactComponent as CheckedIcon } from 'assets/icons/checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/icons/unchecked.svg';

import { Theme } from '@mui/material/styles';

export default function Checkbox(theme: Theme) {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          marginLeft: 10,
          marginRight: 10,
          '& .MuiSvgIcon-root': {
            borderRadius: 10,
          },
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.action.disabled,
          },
          '& .MuiSvgIcon-fontSizeMedium': {
            width: 26,
            height: 26,
            color: theme.palette.secondary.darker,
          },
          '& .MuiSvgIcon-fontSizeSmall': {
            width: 22,
            height: 22,
            color: theme.palette.primary.main,
          },
          svg: {
            fontSize: 26,
            '&[font-size=small]': {
              fontSize: 22,
            },
            color: theme.palette.primary.main,
          },
        },
      },
      defaultProps: {
        icon: <UncheckedIcon />,
        checkedIcon: <CheckedIcon />,
        indeterminateIcon: <CheckboxPartial />,
      },
    },
  };
}

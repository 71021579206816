import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';

import { Theme } from '@mui/material';

import './index.css';

export default function Input(theme: Theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }: any) => ({
          ...(!ownerState.label && {
            '& .MuiInputBase-input': {
              paddingTop: '5px',
              paddingBottom: '5px',
            },
            '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
              paddingTop: '5px',
              paddingBottom: '5px',
            },
            [theme.breakpoints.up('lg')]: {
              '& .MuiInputBase-input': {
                paddingTop: '9px',
                paddingBottom: '9px',
              },
              '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                paddingTop: '9px',
                paddingBottom: '9px',
              },
            },
          }),
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12)',
          color: theme.palette.primary.main,
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.hover,
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.divider,
            transition: 'all 0.2s',
          },
          '& .MuiInputAdornment-root, & .MuiInputAdornment-root > .MuiIconButton-root': {
            color: 'inherit',
          },
        },
        input: ({ ownerState }: any) => ({
          fontSize: '14px',
          padding: '11px 12px 7px',
          lineHeight: '18px',
          height: '18px',
          ...(ownerState.startAdornment && {
            paddingLeft: 0,
          }),
          ...(ownerState.endAdornment && {
            paddingRight: 0,
          }),
        }),
        notchedOutline: {
          top: 0,
          '& legend': {
            display: 'none',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState }: any) => ({
          fontSize: '14px',
          transform: 'translate(12px, 6.5px) scale(1)',
          lineHeight: '1.3rem',
          color: theme.palette.secondary.dark,
          '&.Mui-focused': {
            transform: `translate(12px, 0px) scale(${10 / 14})`,
            opacity: 0.6,
          },
          '&.MuiFormLabel-filled': {
            transform: `translate(12px, 0px) scale(${10 / 14})`,
            opacity: 0.6,
          },
          ...(ownerState.shrink && {
            textTransform: 'uppercase',
            fontWeight: 400,
            color: theme.palette.primary.light,
            transform: `translate(12px, 0px) scale(${10 / 14})`,
          }),
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          color: theme.palette.primary.main,
          paddingTop: 0,
          paddingBottom: 0,
          '& ::placeholder': {
            color: theme.palette.secondary.dark,
            opacity: 1,
          },
        },
        root: {
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            paddingTop: '11px',
            paddingBottom: '7px',
          },
        },
        paper: {
          border: `1px solid ${theme.palette.primary.main}`,
          boxShadow: '0px 2px 5px rgba(142, 142, 142, 0.2), 0px 2px 10px rgba(142, 142, 142, 0.14), 0px 5px 14px rgba(142, 142, 142, 0.12)',
          backgroundColor: '#F5F3F1',
          borderRadius: '4px',
        },
        listbox: {
          padding: 0,
        },
        option: {
          fontSize: '14px',
          padding: '5px 16px',
          color: theme.palette.primary.main,
        },
        groupLabel: {
          top: 0,
          lineHeight: '32px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '26px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          '&.MuiOutlinedInput-root': {
            borderRadius: '4px',
            '& > fieldset': {
              display: 'none !important',
            },
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ChevronDown,
      },
      styleOverrides: {
        root: {
          border: '1px solid #4559A7',
        },
        icon: {
          right: '16px',
        },
        select: {
          '&.MuiOutlinedInput-input': {
            minHeight: 'auto',
            padding: '5px 40px 5px 16px',
            [theme.breakpoints.up('lg')]: {
              padding: '9px 40px 9px 16px',
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '4px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          '&.MuiTypography-root': {
            fontWeight: 400,
            fontSize: '14px !important',
            lineHeight: '18px',
            // fontStyle: 'italic',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.customShadows.z2,
          borderRadius: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '10px',
          lineHeight: '12px',
          fontWeight: 800,
          fontVariant: 'all-small-caps',
        },
      },
    },
  };
}

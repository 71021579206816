import React, { Dispatch, SetStateAction } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as MiscFiltersIcon } from 'assets/icons/misc-filters.svg';
import dayjs from 'dayjs';
import _ from 'lodash';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { GridFilterModel } from '@mui/x-data-grid-pro';

import { makeFilterId } from '../../utils/filters';

interface MiscFiltersProps {
  filterModel: GridFilterModel;
  setFilterModel: Dispatch<SetStateAction<GridFilterModel>>;
  deviceColumnHidden: boolean;
  // visitorsChecked: Record<string, boolean>;
  // setVisitorsChecked: Dispatch<SetStateAction<Record<string, boolean>>>;
}

const GroupHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 800,
  fontSize: '14px',
  fontVariant: 'all-small-caps',
  marginBottom: '4px',
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: '6px',
  marginBottom: '6px',
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    lineHeight: '18px',
  },
  '& .MuiCheckbox-root': {
    marginRight: '16px',
  },
  '& .MuiCheckbox-root .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    width: '16px',
    height: '16px',
  },
}));

export default function MiscFilters({
  filterModel,
  setFilterModel,
  deviceColumnHidden,
  // visitorsChecked,
  // setVisitorsChecked,
}: MiscFiltersProps) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'misc-filters-popover' : undefined;

  const productsFilter =
    filterModel.items.find((x) => x.field === 'products' && x.operator === 'select')?.value ?? [];
  const deviceFilter =
    filterModel.items.find((x) => x.field === 'device' && x.operator === 'select')?.value ?? [];
  const significantFilter =
    filterModel.items.find((x) => x.field === 'significant')?.value;
  const endsFilter = filterModel.items.find((x) => x.field === 'ends');
  const activeCampaignsChecked =
    endsFilter?.operator === 'after' &&
    dayjs(endsFilter?.value).isSame(dayjs().subtract(1, 'day'), 'day');
  const pastCampaignsChecked =
    endsFilter?.operator === 'before' && dayjs(endsFilter?.value).isSame(dayjs(), 'day');
  const activeFiltersCount =
    // Object.values(visitorsChecked).filter((x) => x).length +
    productsFilter.length +
    deviceFilter.length +
    (activeCampaignsChecked || pastCampaignsChecked ? 1 : 0);
  const makeOnSelectChange =
    (field: string) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setFilterModel((currentFilterModel) => {
        const existingSelectFilter = currentFilterModel.items.find(
          (x) => x.field === field && x.operator === 'select'
        );
        const value = (() => {
          if (target.checked) {
            return existingSelectFilter
              ? _.uniq([...existingSelectFilter.value, target.name])
              : [target.name];
          }
          return existingSelectFilter!.value.filter((x: string) => x !== target.name);
        })();
        return {
          ...currentFilterModel,
          items: [
            ...currentFilterModel.items.filter((x) => x.field !== field),
            ...(value.length > 0
              ? [
                  {
                    id: makeFilterId(),
                    field,
                    operator: 'select',
                    value,
                  },
                ]
              : []),
          ],
        };
      });
    };
  return (
    <>
      <Box
        sx={{
          marginLeft: '12px',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '40px',
          position: 'relative',
          color: theme.palette.primary.main,
          display: { xs: 'none', lg: 'block' },
          ...(activeFiltersCount > 0 && {
            marginRight: '33.5px',
          }),
        }}
      >
        <Button
          color="inherit"
          size="large"
          sx={{
            minWidth: 'auto',
            paddingLeft: '9px',
            paddingRight: '9px',
            borderRadius: '50%',
            width: '36px',
            height: '36px',
            ...(activeFiltersCount > 0 && {
              borderRadius: '40px',
              padding: 0,
              width: '26px',
              height: '26px',
              marginLeft: '5px',
            }),
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
              color: 'white',
            },
          }}
          onClick={handleClick}
          aria-label="Misc filters"
        >
          <MiscFiltersIcon />
        </Button>
        {activeFiltersCount > 0 && (
          <>
            <Button
              color="inherit"
              size="large"
              sx={{
                minWidth: 'auto',
                borderRadius: '40px',
                padding: '4px 6px',
                margin: '5px 5px 5px 0',
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                  color: 'white',
                },
              }}
              onClick={() => {
                setFilterModel({
                  ...filterModel,
                  items: filterModel.items.filter(
                    (x) => !['device', 'products', 'ends'].includes(x.field)
                  ),
                });
                // setVisitorsChecked({
                //   firstPurchase: false,
                //   repeatPurchaser: false,
                //   rewardsMember: false,
                // });
              }}
            >
              Clear
            </Button>
            <Box
              sx={{
                position: 'absolute',
                top: '-1px',
                right: '-3px',
                backgroundColor: '#8BD267',
                color: 'white',
                borderRadius: '50%',
                fontSize: '14px',
                lineHeight: '14px',
                width: '16px',
                height: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {activeFiltersCount}
            </Box>
          </>
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: (anchorEl?.clientHeight || 0) + 6,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '4px 10px 10px 16px',
          },
        }}
      >
        <IconButton
          size="small"
          aria-label="Close"
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {/*
        <GroupHeader>Visitors</GroupHeader>
        <FormGroup
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            setVisitorsChecked({
              ...visitorsChecked,
              [target.name]: target.checked,
            });
          }}
        >
          <StyledFormControlLabel
            control={<Checkbox name="firstPurchase" />}
            label="First Purchase"
            checked={visitorsChecked.firstPurchase}
          />
          <StyledFormControlLabel
            control={<Checkbox name="repeatPurchaser" />}
            label="Repeat Purchaser"
            checked={visitorsChecked.repeatPurchaser}
          />
          <StyledFormControlLabel
            control={<Checkbox name="rewardsMember" />}
            label="Rewards Member"
            checked={visitorsChecked.rewardsMember}
          />
        </FormGroup>
        */}
        <GroupHeader>Products</GroupHeader>
        <FormGroup onChange={makeOnSelectChange('products')}>
          <StyledFormControlLabel
            control={<Checkbox name="cc" />}
            label="Cart Creator"
            checked={productsFilter.includes('cc')}
          />
          <StyledFormControlLabel
            control={<Checkbox name="cs" />}
            label="Cart Saver"
            checked={productsFilter.includes('cs')}
          />
          <StyledFormControlLabel
            control={<Checkbox name="rule" />}
            label="Rule"
            checked={productsFilter.includes('rule')}
          />
        </FormGroup>
        <GroupHeader>Devices</GroupHeader>
        <FormGroup onChange={makeOnSelectChange('device')}>
          <StyledFormControlLabel
            control={<Checkbox name="Desktop" />}
            label="Desktop"
            checked={deviceFilter.includes('Desktop')}
            disabled={deviceColumnHidden}
          />
          <StyledFormControlLabel
            control={<Checkbox name="Tablet" />}
            label="Tablet"
            checked={deviceFilter.includes('Tablet')}
            disabled={deviceColumnHidden}
          />
          <StyledFormControlLabel
            control={<Checkbox name="Mobile" />}
            label="Mobile"
            checked={deviceFilter.includes('Mobile')}
            disabled={deviceColumnHidden}
          />
        </FormGroup>
        <GroupHeader>Campaigns</GroupHeader>
        <FormGroup
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            setFilterModel((currentFilterModel) => ({
              ...currentFilterModel,
              items: [
                ...currentFilterModel.items.filter((x) => x.field !== 'ends'),
                ...(target.checked
                  ? [
                      {
                        id: makeFilterId(),
                        field: 'ends',
                        operator: target.name === 'active' ? 'after' : 'before',
                        value: target.name === 'active' ? dayjs().subtract(1, 'day') : dayjs(),
                      },
                    ]
                  : []),
              ],
            }));
          }}
        >
          <StyledFormControlLabel
            control={<Checkbox name="active" />}
            label="Active Campaigns"
            checked={activeCampaignsChecked}
          />
          <StyledFormControlLabel
            control={<Checkbox name="past" />}
            label="Past Campaigns"
            checked={pastCampaignsChecked}
          />
        </FormGroup>
        <GroupHeader>Campaign / engagement relevance</GroupHeader>
        <FormGroup
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            setFilterModel((currentFilterModel) => ({
              ...currentFilterModel,
              items: [
                ...currentFilterModel.items.filter((x) => x.field !== 'significant'),
                ...(target.checked
                  ? [
                      {
                        id: makeFilterId(),
                        field: 'significant',
                        operator: 'checkbox',
                        value: target.name === 'significant',
                      },
                    ]
                  : []),
              ],
            }));
          }}
        >
          <StyledFormControlLabel
            control={<Checkbox name="significant" />}
            label="Statistically Significant"
            checked={significantFilter === true}
          />
          <StyledFormControlLabel
            control={<Checkbox name="insignificant" />}
            label="Statisticaly Insignificant"
            checked={significantFilter === false}
          />
        </FormGroup>
      </Popover>
    </>
  );
}

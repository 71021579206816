import React, { useContext } from 'react';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/system';
import {
  GridColDef,
  gridExpandedSortedRowEntriesSelector, GridFilterModel, GridLogicOperator,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import { ExpandedRowsContext } from '../DashboardGroupingCell';

interface CampaignHeaderProps {
  setFilterModel: (filterModel: GridFilterModel) => void;
  hiddenColumns: string[];
  colDef: GridColDef;
}

export default function CampaignHeader({ setFilterModel, hiddenColumns, colDef }: CampaignHeaderProps) {
  const theme = useTheme();
  const apiRef = useGridApiContext();
  const { rows, setRows } = useContext(ExpandedRowsContext);
  const apiRows = useGridSelector(apiRef, gridExpandedSortedRowEntriesSelector);
  const campaignGroupRows = apiRows.filter(({ id, model }) => {
    const rowNode = apiRef.current.getRowNode(id);
    return !('offer' in model) && !('device' in model) && rowNode?.type === 'group';
  });
  const allCampaignsExpanded = campaignGroupRows.every(({ id }) => rows[id]);
  const isOfferVisible = !hiddenColumns.includes('offer');
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexGrow: 1,
      }}
    >
      <Tooltip title={colDef.description}>
        <Box
          sx={{
            lineHeight: '1',
            paddingBottom: '6px',
            [theme.breakpoints.up('lg')]: {
              paddingTop: '6px',
            },
          }}
          aria-label="Campaign"
        >
          Campaign
        </Box>
      </Tooltip>
      {isOfferVisible && (
        <IconButton
          size="small"
          onClick={(event) => {
            campaignGroupRows.forEach(({ id }) => {
              apiRef.current.setRowChildrenExpansion(id, !allCampaignsExpanded);
            });
            setRows({
              ...rows,
              ...Object.fromEntries(campaignGroupRows.map(({ id }) => [id, !allCampaignsExpanded])),
            });
            setFilterModel({
              items: [],
              logicOperator: GridLogicOperator.And,
            });
            event.stopPropagation();
          }}
          sx={{ display: { xs: 'none', lg: 'inline-flex' }, marginTop: '-2px' }}
        >
          {allCampaignsExpanded ? <ArrowLeft /> : <ArrowRight />}
        </IconButton>
      )}
    </Box>
  );
}

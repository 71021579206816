import { Components, Theme } from '@mui/material';

const tooltipOverrides: Components<Theme> = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        padding: '16px',
        backgroundColor: theme.palette.secondary.dark,
        color: 'white',
        borderRadius: '4px',
        fontSize: '12px',
        maxWidth: '200px',
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
      }),
    },
  },
};

export default tooltipOverrides;

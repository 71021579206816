import { Theme } from '@mui/material/styles';
import { Components } from '@mui/material/styles/components';

const sliderOverrides: Components<Theme> = {
  MuiSlider: {
    styleOverrides: {
      root: {
        margin: '8px 24px 0',
        '&.MuiSlider-dragging': {
          '& .MuiSlider-thumb': {
            pointerEvents: 'none !important' as any,
            '&.Mui-active': {
              pointerEvents: 'initial !important' as any,
            },
          },
        },
      },
      rail: ({ theme }) => ({
        borderRadius: 0,
        backgroundColor: theme.palette.secondary.dark,
      }),
      track: {
        border: 'none',
      },
      valueLabel: ({ theme }) => ({
        background: 'none',
        top: '5px',
        color: theme.palette.primary.main,
        fontVariant: 'all-small-caps',
        fontWeight: 800,
      }),
      thumb: {
        width: 22,
        height: 22,
        pointerEvents: 'initial !important' as any,
        '&:hover, &.Mui-focusVisible': {
          boxShadow: 'none',
        },
      },
    },
  },
};

export default sliderOverrides;

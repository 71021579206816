import React, { useEffect, useState } from 'react';
import { AutocompleteElement, Controller } from 'react-hook-form-mui';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import axios from 'axios';
import useDebounce from 'hooks/useDebounce';
import { formatSubLabel } from 'utils/global';

import { FormHelperText } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const geoName = process.env.REACT_APP_GEO_NAME;

export function FormSelect({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}
type TimezoneSelectProps = {
  onInputChange: (value: string) => void;
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
};

type TimezoneSelectOption = {
  value: number;
  name: string;
  label: string;
};

function TimezoneSelect({
  name,
  label,
  placeholder,
  required,
  onInputChange,
}: TimezoneSelectProps) {
  const [options, setOptions] = useState<TimezoneSelectOption[]>([]);
  const [query, setQuery] = useState('');
  const deferredQuery = useDebounce(query, 600);

  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [isTimezoneLoading, setIsTimezoneLoading] = useState(false);

  const getLocation = async (value: string) => {
    setIsLocationLoading(true);
    const { data } = await axios.get('https://secure.geonames.net/searchJSON', {
      params: {
        username: geoName,
        q: value,
        maxRows: 5,
      },
    });
    setOptions(
      data.geonames.map((x: any) => ({
        value: x.geonameId,
        name: x.name,
        label: formatSubLabel(x),
      }))
    );
    setIsLocationLoading(false);
  };

  const getTimezone = async (geonameId: number) => {
    setIsTimezoneLoading(true);
    const { data } = await axios.get('https://secure.geonames.net/getJSON', {
      params: {
        username: geoName,
        geonameId,
      },
    });
    onInputChange(data.timezone ? data.timezone.timeZoneId : '');
    setIsTimezoneLoading(false);
  };

  useEffect(() => {
    getLocation(deferredQuery);
  }, [deferredQuery]);

  return (
    <AutocompleteElement
      name={name}
      options={options.map((option) => option.value.toString())}
      label={label}
      loading={isLocationLoading || isTimezoneLoading}
      autocompleteProps={{
        filterOptions: (optionItems) => optionItems,
        isOptionEqualToValue: (option, value) => option.value === value.value,
        renderOption: (props, option) => (
          <MenuItem
            sx={{
              backgroundColor: 'white !important',
              '&.Mui-focused': { backgroundColor: 'rgba(69, 89, 167, 0.16) !important' },
            }}
            {...props}
          >
            <Stack>
              <Typography variant='body1'>
                {options.find((item) => item.value.toString() === option)?.name}
              </Typography>
              <Typography variant='body'>
                {options.find((item) => item.value.toString() === option)?.label}
              </Typography>
            </Stack>
          </MenuItem>
        ),
        onChange: (_, param) => getTimezone(parseInt(param, 10)),
        onInputChange: (_, value) => setQuery(value),
        onOpen: () => {
          setQuery('');
          onInputChange('');
        },
      }}
      textFieldProps={{
        InputProps: {
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: null,
        },
        sx: {
          '& .MuiAutocomplete-input': {
            color: isTimezoneLoading ? 'white' : undefined,
          },
        },
        placeholder,
      }}
      required={required}
    />
  );
}

interface MultiSelectProps {
  name: string;
  label?: string;
  placeholder?: string;
  options: { id: number | string; label: string }[];
  control?: any;
  formControlSx?: any;
  selectSx?: any;
  showCheckbox?: boolean;
  showAllItem?: boolean;
  required?: boolean;
  disabled?: boolean;
}

function MultiSelect({
  name,
  label,
  placeholder,
  options,
  control,
  formControlSx,
  selectSx,
  showAllItem,
  showCheckbox,
  required,
  disabled,
}: MultiSelectProps) {
  const theme = useTheme();

  return (
    <FormControl sx={formControlSx}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field: { name: fieldName, value = [], onChange: fieldChange }, fieldState }) => (
          <>
            <Select
              name={fieldName}
              value={value}
              onChange={(event) => {
                const values = event.target.value;

                if (values.includes('all')) {
                  if (value.length === options.length) fieldChange([]);
                  else fieldChange(options.map(({ id }) => id));
                } else {
                  fieldChange(event.target.value);
                }
              }}
              renderValue={() =>
                value.length > 0
                  ? options
                      .filter((item) => value.includes(item.id))
                      .map((item) => item.label)
                      .join(', ')
                  : placeholder
              }
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP },
                },
                MenuListProps: { sx: { paddingY: 0 } },
              }}
              displayEmpty
              multiple
              sx={selectSx}
              disabled={disabled}
            >
              {showAllItem ? (
                <MenuItem key='all' value='all'>
                  {showCheckbox ? (
                    <Checkbox
                      checked={value.length === options.length}
                      sx={{ margin: '0 16px 0 0' }}
                    />
                  ) : null}
                  <ListItemText primary='All' primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
              ) : null}

              {options.map((item) => (
                <MenuItem key={item.id} value={item.id} sx={{ padding: '3px 16px 3px 16px' }}>
                  {showCheckbox ? (
                    <Checkbox checked={value.includes(item.id)} sx={{ margin: '0 16px 0 0' }} />
                  ) : null}
                  <ListItemText primary={item.label} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
              ))}
            </Select>
            {fieldState.error && (
              <FormHelperText error>
                {fieldState.error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
}

FormSelect.MultiSelect = MultiSelect;
FormSelect.TimezoneSelect = TimezoneSelect;

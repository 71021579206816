export const formatSubLabel = (x: any) => {
  if (x.adminName1 && x.countryName && x.adminName1 !== x.name && x.countryName !== x.name) {
    return `${x.adminName1}, ${x.countryName}`;
  }
  if (x.countryName && x.countryName !== x.name) {
    return x.countryName;
  }
  return '-------';
};

export function validateEmail(email: string) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!regex.test(email)) {
    return 'Invalid email address';
  }
  return true; // Return empty string if validation succeeds
}

type Props = {
  timezone: string;
};

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';

import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FilterPanelPropsOverrides } from '@mui/x-data-grid/models/gridSlotsComponentsProps';
import {
  GridColType,
  gridColumnLookupSelector,
  GridFilterModel,
  GridFilterOperator,
  gridSortedRowEntriesSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { DatePicker, DateRange } from '@mui/x-date-pickers-pro';

import { makeFilterId } from '../../utils/filters';
import DashboardSelect from '../DataGrid/DashboardSelect';
import DateRangePicker from '../DateRangePicker';

import {
  booleanOperators,
  dateOperators,
  listOperators,
  numberOperators,
  stringOperators,
} from './operators';

declare module '@mui/x-data-grid/models/gridSlotsComponentsProps' {
  interface FilterPanelPropsOverrides {
    filterModel: GridFilterModel;
    setFilterModel: Dispatch<SetStateAction<GridFilterModel>>;
    column: string;
    columnType: GridColType;
  }
}

const StyledSelect = styled(TextField)(({ theme }) => ({
  width: '137px',
  '& .MuiOutlinedInput-root': {
    background: 'transparent',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '& .MuiSelect-select.MuiInputBase-input': {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  '& .MuiFormLabel-root': {
    transform: 'translate(12px, 4.5px) scale(1)',
    color: theme.palette.primary.main,
  },
}));

function FilterPanel({
  filterModel,
  setFilterModel,
  column,
  columnType,
}: FilterPanelPropsOverrides) {
  const apiRef = useGridApiContext();
  const theme = useTheme();
  const columnOperators = (() => {
    if (columnType === 'date') {
      return dateOperators;
    }
    if (columnType === 'number') {
      return numberOperators;
    }
    if (columnType === 'list') {
      return listOperators;
    }
    if (columnType === 'boolean') {
      return booleanOperators;
    }
    return stringOperators;
  })();
  const existingFilter = filterModel.items.find((x) => x.field === column);
  const initialOperator = existingFilter
    ? (columnOperators.find((x) => x.value === existingFilter.operator) as GridFilterOperator)
    : '';
  const initialSearch = existingFilter ? existingFilter.value : '';
  const [selectedOperator, setSelectedOperator] = useState<GridFilterOperator | ''>(
    initialOperator
  );
  const [search, setSearch] = useState<any>(initialSearch);
  const [rerenderSearch, setRerenderSearch] = useState(false);
  useEffect(() => {
    if (columnType === 'date') {
      setRerenderSearch(true);
    }
  }, [columnType, selectedOperator]);
  useEffect(() => {
    if (rerenderSearch) {
      setRerenderSearch(false);
    }
  }, [rerenderSearch]);
  useEffect(() => {
    setSelectedOperator(initialOperator);
    setSearch(initialSearch);
  }, [column]);
  const columnDefinitions = gridColumnLookupSelector(apiRef);
  const selectOptions = _.uniqBy(gridSortedRowEntriesSelector(apiRef), (x) => x.model[column]);
  const listSelectOptions: string[] = _.uniq(
    gridSortedRowEntriesSelector(apiRef).flatMap((x) => {
      const defaultValue = x.model[column];
      const colDef = columnDefinitions[column];
      if (colDef?.valueGetter) {
        return colDef.valueGetter({ row: x.model, field: column, value: defaultValue } as any);
      }
      return defaultValue;
    })
  ).sort();
  const renderSearchFields = () => {
    if (rerenderSearch) {
      return null;
    }
    if (selectedOperator && selectedOperator.value === 'checkbox') {
      return (
        <Checkbox
          aria-label="Checkbox filter"
          checked={search}
          onChange={({ target }) => setSearch(target.checked)}
        />
      );
    }
    if (
      columnType === 'list' &&
      selectedOperator &&
      selectedOperator.value === 'select' &&
      Array.isArray(search)
    ) {
      return (
        <StyledSelect
          select
          label="select"
          onChange={({ target }) => {
            setSearch([...(search as string[]), target.value]);
          }}
          value=""
        >
          {listSelectOptions
            .filter((x) => x && !search.includes(x))
            .map((x) => (
              <MenuItem key={x} value={x}>
                {x}
              </MenuItem>
            ))}
        </StyledSelect>
      );
    }
    if (selectedOperator && selectedOperator.value === 'select' && Array.isArray(search)) {
      return (
        <StyledSelect
          select
          label="select"
          onChange={({ target }) => {
            if (target.value === 'SELECT_ALL') {
              setSearch(selectOptions.map((x) => x.model[column]));
            } else {
              setSearch([...(search as string[]), target.value]);
            }
          }}
          value=""
        >
          <MenuItem value="SELECT_ALL">All</MenuItem>
          {selectOptions
            .filter((x) => x.model[column] && !search.includes(x.model[column]))
            .map(({ model }) => (
              <MenuItem key={model.id} value={model[column]}>
                {model[column]}
              </MenuItem>
            ))}
        </StyledSelect>
      );
    }
    if (
      columnType === 'date' &&
      selectedOperator &&
      ['is', 'before', 'after'].includes(selectedOperator.value)
    ) {
      return (
        <DatePicker
          value={search}
          onChange={(value) => setSearch(dayjs(value).startOf('day'))}
          slotProps={{ textField: { inputProps: { 'aria-label': 'Date' } } }}
        />
      );
    }
    if (columnType === 'date' && selectedOperator && selectedOperator.value === 'between') {
      return (
        <DateRangePicker
          value={search as DateRange<Dayjs>}
          onChange={(value) => setSearch(value)}
          TextFieldProps={{ inputProps: { 'aria-label': 'Date range' } }}
        />
      );
    }
    if (
      columnType === 'date' &&
      selectedOperator &&
      [
        'today',
        'yesterday',
        'thisWeek',
        'lastWeek',
        'thisMonth',
        'lastMonth',
        'thisQuarter',
        'lastQuarter',
        'thisYear',
        'lastYear',
      ].includes(selectedOperator.value)
    ) {
      return null;
    }
    if (columnType === 'number' && selectedOperator && selectedOperator.value === 'between') {
      return (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
            <Typography
              component="label"
              htmlFor="filter-search-one"
              sx={{
                width: '110px',
                marginLeft: '16px',
                fontSize: '14px',
                color: theme.palette.primary.main,
              }}
            >
              Greater than
            </Typography>
            <TextField
              inputProps={{ id: 'filter-search-one' }}
              sx={{
                width: '135px',
                '& .MuiInputBase-input': {
                  paddingTop: '5px',
                  paddingBottom: '5px',
                },
              }}
              value={search[0]}
              onChange={({ target }) => setSearch([target.value, search[1]])}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
            <Typography
              component="label"
              htmlFor="filter-search-two"
              sx={{
                width: '110px',
                marginLeft: '16px',
                fontSize: '14px',
                color: theme.palette.primary.main,
              }}
            >
              less than
            </Typography>
            <TextField
              inputProps={{ id: 'filter-search-two' }}
              sx={{
                width: '135px',
                '& .MuiInputBase-input': {
                  paddingTop: '5px',
                  paddingBottom: '5px',
                },
              }}
              value={search[1]}
              onChange={({ target }) => setSearch([search[0], target.value])}
            />
          </Box>
        </>
      );
    }
    return (
      <TextField
        placeholder="search"
        sx={{
          width: '137px',
          '& .MuiInputBase-input': {
            paddingTop: '5px',
            paddingBottom: '5px',
          },
        }}
        value={search}
        onChange={({ target }) => setSearch(target.value)}
      />
    );
  };
  const getApplyDisabled = () => {
    if (!selectedOperator) {
      return true;
    }
    if (columnType === 'date') {
      if (['is', 'before', 'after'].includes(selectedOperator.value)) {
        return !search;
      }
      if (selectedOperator.value === 'between') {
        return !(search[0] && search[1]);
      }
      return false;
    }
    if (selectedOperator.value === 'checkbox') {
      return false;
    }
    return search.length === 0;
  };
  return (
    <Box
      sx={{
        padding: '8px 8px 10px 8px',
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginBottom: '8px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 800,
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            fontSize: 14,
            lineHeight: '20px',
            fontVariant: 'all-small-caps',
          }}
        >
          Filter
        </Typography>
        <IconButton
          size="small"
          sx={{ margin: '-8px -8px 0 0' }}
          onClick={() => apiRef.current.hideFilterPanel()}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          ...(columnType === 'number' &&
            selectedOperator &&
            selectedOperator.value === 'between' && {
              flexDirection: 'column',
              alignItems: 'flex-start',
            }),
        }}
      >
        <DashboardSelect
          select
          SelectProps={{ SelectDisplayProps: { 'aria-label': 'Operator' } }}
          label={selectedOperator ? undefined : 'Choose one'}
          sx={{ width: '190px', marginRight: '12px' }}
          value={selectedOperator}
          onChange={({ target }: any) => {
            setSelectedOperator(target.value);
            if (columnType === 'date') {
              if (['is', 'before', 'after'].includes(target.value.value)) {
                setSearch(null);
              } else if (target.value.value === 'between') {
                setSearch([null, null]);
              } else {
                setSearch('');
              }
            } else if (columnType === 'number' && target.value.value === 'between') {
              setSearch(['', '']);
            } else if (target.value.value === 'select') {
              setSearch([]);
            } else if (target.value.value === 'checkbox') {
              setSearch(false);
            } else {
              setSearch('');
            }
          }}
        >
          {columnOperators.map((operator) => (
            <MenuItem key={operator.value} value={operator as any}>
              {operator.label}
            </MenuItem>
          ))}
        </DashboardSelect>
        {renderSearchFields()}
      </Box>
      {selectedOperator && selectedOperator.value === 'select' && Array.isArray(search) && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: '8px -2px',
          }}
        >
          {(search as string[]).map((item) => (
            <Chip
              key={item}
              label={item}
              onDelete={() => setSearch((search as string[]).filter((x) => x !== item))}
              sx={{
                margin: '4px 2px',
                fontSize: '14px',
                lineHeight: '14px',
                fontWeight: 800,
                fontVariant: 'small-caps',
                color: theme.palette.primary.dark,
                background: theme.palette.secondary.light,
                '& .MuiSvgIcon-root': {
                  fontSize: '14px',
                  color: theme.palette.primary.dark,
                  marginRight: '12px',
                },
              }}
            />
          ))}
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: '18px',
        }}
      >
        <Button
          size="small"
          variant="text"
          sx={{ marginRight: '8px' }}
          onClick={() => {
            setSelectedOperator('');
            setSearch('');
            setFilterModel((prevState) => ({
              ...prevState,
              items: prevState.items.filter((x) => !(x.field === column)),
            }));
          }}
        >
          Clear
        </Button>
        <Button
          size="small"
          variant="contained"
          disabled={getApplyDisabled()}
          onClick={() => {
            setFilterModel((prevState) => ({
              ...prevState,
              items: [
                ...prevState.items.filter((x) => !(x.field === column)),
                {
                  id: makeFilterId(),
                  field: column,
                  operator: (selectedOperator as GridFilterOperator).value,
                  value: search,
                },
              ],
            }));
            apiRef.current.hideFilterPanel();
          }}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
}

export default FilterPanel;

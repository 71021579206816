import { Divider, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const StyledDivider = styled(Divider)(({ theme }) => ({
  fontVariant: 'all-small-caps',
  color: theme.palette.primary.dark,
  fontWeight: 800,
  fontSize: '14px',
  '&, &::before, &::after': {
    borderColor: theme.palette.secondary.light,
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '12px',
  lineHeight: '15px',
  marginLeft: '12px',
  marginRight: '12px',
}));

export const ErrorMessage = styled(Box)({
  width: '100%',
  padding: '6.5px 16px',
  backgroundColor: '#EF6C00',
  borderRadius: '4px',
  color: 'white',
  fontSize: '18px',
  fontWeight: 800,
  lineHeight: '23px',
  marginBottom: '12px',
});

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as OrganizationIcon } from 'assets/icons/organization.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as RolesIcon } from 'assets/icons/roles.svg';
import dayjs from 'dayjs';
import PATH_NAMES from 'routes/paths';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';

import { SessionContext, supabase } from '../../../auth';
import { IProfileDomain } from '../../../types/users';

import { HeaderContext } from './context';

export default function UserMenu() {
  const theme = useTheme();
  const { profile, setProfile } = useContext(SessionContext);
  const { domainId, organizationId } = useContext(HeaderContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const menuId = open ? 'user-menu' : undefined;
  if (!profile) {
    return null;
  }
  const isMetrical = profile.organizations.some((x) => x.master);
  const { start: domainStart } = profile.organizations
    .find((x) => x.id === organizationId)!
    .domains.find((x) => x.id === domainId) as IProfileDomain;
  return (
    <>
      <Button
        aria-describedby={menuId}
        sx={{
          width: '30px',
          height: '30px',
          border: '2px solid white',
          borderRadius: '50%',
          color: 'white',
          fontWeight: 800,
          minWidth: 'initial',
          marginLeft: {
            xs: 0,
            lg: '12px',
          },
          textTransform: 'uppercase',
          ...(profile.avatarUrl && {
            backgroundImage: `url(${profile.avatarUrl})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }),
        }}
        onClick={handleClick}
        aria-label="User menu"
      >
        {!profile.avatarUrl && ((profile.firstName && profile.lastName)
          ? `${profile.firstName.substring(0, 1)}${profile.lastName.substring(0, 1)}`
          : profile.email.substring(0, 2))}
      </Button>
      <Menu
        id={menuId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: (anchorEl?.clientHeight || 0) + 15,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiMenuItem-root': {
            paddingLeft: '18px',
            paddingRight: '18px',
          },
          '& .MuiListItemIcon-root': {
            width: '42px',
          },
          '& .MuiListItemIcon-root svg': {
            width: '26px',
            height: '26px',
          },
        }}
      >
        <MenuItem component={Link} to={PATH_NAMES.profile.root} onClick={() => setAnchorEl(null)}>
          <ListItemIcon>
            <ProfileIcon />
          </ListItemIcon>
          <ListItemText>
            {(profile.firstName && profile.lastName) ? `${profile.firstName} ${profile.lastName}` : profile.email}
          </ListItemText>
        </MenuItem>
        <Box
          sx={{
            padding: '8px 18px 12px 68px',
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: 800,
            fontVariant: 'all-small-caps',
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
          }}
        >
          {(isMetrical || profile.organizations.length === 1) && (
            <Typography
              sx={{
                color: theme.palette.primary.main,
                lineHeight: 'inherit',
                fontWeight: 'inherit',
              }}
            >
              {isMetrical ? 'Metrical' : profile.organizations[0].name}
            </Typography>
          )}
          {profile.admin && (
            <Typography
              sx={{
                color: theme.palette.secondary.main,
                lineHeight: 'inherit',
                fontWeight: 'inherit',
              }}
            >
              Admin
            </Typography>
          )}
          {(!isMetrical && profile.organizations.length === 1) && (
            <Typography
              sx={{
                color: theme.palette.primary.main,
                lineHeight: 'inherit',
                fontWeight: 400,
                fontVariant: 'normal',
              }}
            >
              Customer since {dayjs(domainStart).format('MMM YYYY')}
            </Typography>
          )}
        </Box>
        {profile.admin && [
          isMetrical ? (
            <MenuItem
              key={0}
              component={Link}
              to={PATH_NAMES.accounts.organization}
              onClick={() => setAnchorEl(null)}
              sx={{ display: { xs: 'none', lg: 'flex' } }}
            >
              <ListItemIcon>
                <OrganizationIcon />
              </ListItemIcon>
              <ListItemText>Organizations</ListItemText>
            </MenuItem>
          ) : (
            <MenuItem
              key={0}
              component={Link}
              to={PATH_NAMES.customer.organization}
              onClick={() => setAnchorEl(null)}
              sx={{ display: { xs: 'none', lg: 'flex' } }}
            >
              <ListItemIcon>
                <OrganizationIcon />
              </ListItemIcon>
              <ListItemText>My organization</ListItemText>
            </MenuItem>
          ),
          <MenuItem
            key={1}
            component={Link}
            to={PATH_NAMES.accounts.roles}
            onClick={() => setAnchorEl(null)}
            sx={{ display: { xs: 'none', lg: 'flex' } }}
          >
            <ListItemIcon>
              <RolesIcon />
            </ListItemIcon>
            <ListItemText>Roles</ListItemText>
          </MenuItem>,
        ]}
        <MenuItem
          onClick={async () => {
            await supabase.auth.signOut();
            setProfile(null);
          }}
        >
          <ListItemIcon>
            <ArrowLeftIcon />
          </ListItemIcon>
          <ListItemText>Log out</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

import { Theme } from '@mui/material/styles';

export default function Menu(theme: Theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
        paper: {
          backgroundColor: theme.palette.secondary.lighter,
        },
        list: {
          padding: 0,
          color: theme.palette.primary.main,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          lineHeight: '1rem',
        },
      },
    },
  };
}

import React, { createContext } from 'react';
import { Dayjs } from 'dayjs';

import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { DateRange } from '@mui/x-date-pickers-pro';

export interface RowTrendColor {
  campaign: string;
  offer?: string;
  color: string;
}

export interface SummaryTrendColor {
  isSummary: true;
  color: string;
}

export type TrendColor = RowTrendColor | SummaryTrendColor;

export interface DashboardContextValue {
  dateRange: DateRange<Dayjs>;
  hoveredTrend: string | null;
  setHoveredTrend: (hoveredTrend: string | null) => void;
  trendColors: TrendColor[];
  setTrendColors: (colors: TrendColor[]) => void;
  apiRef: React.MutableRefObject<GridApiPro>;
}

export default createContext<DashboardContextValue>({
  dateRange: [null, null],
  hoveredTrend: null,
  setHoveredTrend: () => {},
  trendColors: [],
  setTrendColors: () => {},
  apiRef: {} as any,
});

import React, { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { ReactComponent as CheckedBold } from 'assets/icons/checked-bold.svg';
import { ReactComponent as CheckedPartialBold } from 'assets/icons/checked-partial-bold.svg';
import { ReactComponent as ChevronDownGray } from 'assets/icons/chevron-down-gray.svg';
import { ReactComponent as Insignificant } from 'assets/icons/insignificant.svg';
import { ReactComponent as UncheckedBold } from 'assets/icons/unchecked-bold.svg';
import type { IIncrementalRow } from 'types/global';

import Box from '@mui/material/Box';
import { ButtonProps } from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { gridFilteredSortedRowEntriesSelector } from '@mui/x-data-grid/hooks/features/filter/gridFilterSelector';
import {
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  GridRowId,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import DashboardContext from '../pages/DashboardContext';

export interface ExpandedRowsContextValue {
  rows: Record<GridRowId, boolean>;
  setRows: Dispatch<SetStateAction<Record<GridRowId, boolean>>>;
}
export const ExpandedRowsContext = createContext<ExpandedRowsContextValue>({
  rows: {},
  setRows: () => {},
});

export interface ExtraRenderCellParams {
  selectedOffers: IIncrementalRow[] | null;
  setSelectedOffers: React.Dispatch<React.SetStateAction<IIncrementalRow[] | null>>;
  selectEnabled: boolean;
  isTreeData: boolean;
}

export function CustomGridTreeDataGroupingCell(
  props: GridRenderCellParams<IIncrementalRow> & {
    extraRef?: React.RefObject<ExtraRenderCellParams>;
    depth: number;
    showKeywords?: boolean;
  }
) {
  const theme = useTheme();
  const {
    id,
    field,
    rowNode,
    row,
    extraRef,
    depth,
    showKeywords,
  } = props;
  const { selectedOffers, setSelectedOffers, selectEnabled, isTreeData } =
    extraRef?.current || ({} as ExtraRenderCellParams);
  const { rows, setRows } = useContext(ExpandedRowsContext);
  const { trendColors } = useContext(DashboardContext);
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const gridRows = useGridSelector(apiRef, gridFilteredSortedRowEntriesSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
  const parentChildrenCount = gridRows.filter((x) => (
    x.model.campaign === row.campaign && x.model.offer && !x.model.device
  )).length;

  const handleClick: ButtonProps['onClick'] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rows[id]);
    setRows({ ...rows, [id]: !rows[id] });
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };
  const isChecked = selectedOffers?.find((x) => x.id === row.id) !== undefined;
  const isIndeterminate = !isChecked && !row.offer && selectedOffers?.find((x) => x.campaign === row.campaign) !== undefined;
  const cellValue = row[field as 'campaign' | 'offer'];
  const color = trendColors.find((x) => (!('isSummary' in x) && x.offer === row.offer && x.campaign === row.campaign))?.color || '';
  return (
    <Stack
      direction="row"
      width="100%"
    >
      {field === 'campaign' && !('device' in row) && (!('offer' in row) || parentChildrenCount > 1) && (
        <>
          {selectEnabled ? (
            <Checkbox
              inputProps={{ 'aria-label': 'select row' }}
              checked={isChecked}
              onChange={(event, checked) =>
                setSelectedOffers((value) => {
                  if (checked) {
                    return [...value || [], row];
                  }
                  return (value || []).filter((x) => x.id !== row.id);
                })
              }
              indeterminate={isIndeterminate}
              sx={{
                marginLeft: 0,
                '& svg': {
                  color,
                },
              }}
              icon={<UncheckedBold />}
              checkedIcon={<CheckedBold />}
              indeterminateIcon={<CheckedPartialBold />}
            />
          ) : (
            <Box
              sx={{
                width: 17,
                height: 17,
                borderRadius: '3px',
                backgroundColor: color,
                marginRight: '10.5px',
                marginLeft: '0.5px',
                flexShrink: 0,
              }}
            />
          )}
        </>
      )}
      {(rowNode.depth === depth || !isTreeData) && (
        <Stack justifyContent="center" flexGrow="1" overflow="hidden">
          <Stack direction="row" alignItems="center">
            <Box
              sx={{ lineHeight: '15px', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 'auto' }}
              title={cellValue}
            >
              {cellValue}
            </Box>
            {/* row.significant === false && (
              <Tooltip title="The computed indicators are statistically insignificant.">
                <Box component={Insignificant} sx={{ margin: '0 4px', flexShrink: 0 }} />
              </Tooltip>
            ) */}
            {filteredDescendantCount > 0 && (
              <IconButton
                size="small"
                onClick={handleClick}
                aria-label="see children"
                sx={{
                  margin: '-8px 0 -8px 0',
                  display: { xs: 'none', lg: 'inline-flex' },
                }}
              >
                <Box
                  component={ChevronDownGray}
                  sx={{
                    ...(rows[id] ? { transform: 'rotate(180deg)' } : {}),
                    transition: 'transform 0.3s',
                  }}
                />
              </IconButton>
            )}
          </Stack>
          {showKeywords && (
            <Tooltip title={row.keywords.join(' / ')}>
              <Typography
                sx={{
                  color: theme.palette.primary.light,
                  fontSize: '10px',
                  lineHeight: '13px',
                  fontVariant: 'all-small-caps',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  alignSelf: 'flex-start',
                  maxWidth: '100%',
                  '& > span:not(:last-of-type)::after': {
                    content: '" / "',
                  },
                }}
              >
                {row.keywords.map((keyword) => (
                  <span key={keyword}>
                    {keyword}
                  </span>
                ))}
              </Typography>
            </Tooltip>
          )}
        </Stack>
      )}
    </Stack>
  );
}

import React, { useContext, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { Link, Navigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Button from '@mui/material/Button';

import { SessionContext, supabase } from '../../auth';
import { ErrorMessage, StyledTypography } from '../../components/Auth/Styled';
import PasswordField from '../../components/Common/PasswordField';
import paths from '../../routes/paths';

interface ResetPasswordForm {
  password: string;
  repeatPassword: string;
}

export default function ResetPassword() {
  const passwordMessage = 'Minimum 8 characters, including 1 lower case letter, 1 upper case letter and 1 number';
  const formContext = useForm<ResetPasswordForm>({
    resolver: yupResolver(yup.object().shape({
      password: yup.string().required().min(8, passwordMessage)
        .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, passwordMessage),
      repeatPassword: yup.string().required('Repeat new password is a required field')
        .oneOf([yup.ref('password'), ''], 'Passwords must match')
    })),
  });
  const { handleSubmit } = formContext;
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const onSubmit = async ({ password }: ResetPasswordForm) => {
    const { error } = await supabase.auth.updateUser({ password });
    if (error) {
      setUpdateFailed(true);
    } else {
      setPasswordUpdated(true);
    }
  };
  const { session } = useContext(SessionContext);
  if (!session) {
    return (
      <Navigate to={paths.auth.login} />
    );
  }
  const { user: { email } } = session;
  if (passwordUpdated || updateFailed) {
    return (
      <>
        {passwordUpdated ? (
          <StyledTypography>
            Your password has been changed.
          </StyledTypography>
        ) : (
          <ErrorMessage>
            Your password could not be changed.
          </ErrorMessage>
        )}
        <Button
          variant="contained"
          component={Link}
          to={paths.auth.login}
          sx={{ alignSelf: 'center', marginTop: '40px' }}
        >
          Go to login page
        </Button>
      </>
    );
  }
  return (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(onSubmit)}
      FormProps={{ style: { display: 'flex', flexDirection: 'column' } }}
    >
      <StyledTypography sx={{ margin: '0 0 12px 0' }}>
        Resetting password for {email}
      </StyledTypography>
      <PasswordField label="New password" name="password" sx={{ marginBottom: '12px' }} />
      <PasswordField label="Repeat new password" name="repeatPassword" sx={{ marginBottom: '12px' }} />
      <Button variant="contained" sx={{ alignSelf: 'flex-end' }} type="submit">
        Reset password
      </Button>
    </FormContainer>
  );
}

import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = {
  openForm: VoidFunction;
};

function AddOrganizationBar({ openForm }: Props) {
  return (
    <Stack direction="row" alignItems="center" spacing={8} marginBottom={5}>
      <Typography variant="subtitle2">ORGANIZATIONS</Typography>
      <Button onClick={openForm}>+ new organization</Button>
    </Stack>
  );
}

export default AddOrganizationBar;

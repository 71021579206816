import React, { ComponentProps, forwardRef } from 'react';

import { GridBaseColumnHeaders } from '@mui/x-data-grid/components/columnHeaders/GridBaseColumnHeaders';
import { GridColumnHeadersInner } from '@mui/x-data-grid/components/columnHeaders/GridColumnHeadersInner';
import { GridColumnHeaders, GridScrollArea } from '@mui/x-data-grid-pro';

import { useGridColumnHeaders } from '../useGridColumnHeaders';

const ColumnHeaders = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof GridColumnHeaders>
>((props, ref) => {
  const {
    innerRef,
    className,
    visibleColumns,
    sortColumnLookup,
    filterColumnLookup,
    columnPositions,
    columnHeaderTabIndexState,
    columnGroupHeaderTabIndexState,
    columnHeaderFocus,
    columnGroupHeaderFocus,
    densityFactor,
    headerGroupingMaxDepth,
    columnMenuState,
    columnVisibility,
    columnGroupsHeaderStructure,
    hasOtherElementInTabSequence,
    pinnedColumns,
    ...other
  } = props;

  const {
    isDragging,
    getRootProps,
    getInnerProps,
    getColumnHeaders,
    getColumnGroupHeaders,
  } = useGridColumnHeaders({
    innerRef,
    visibleColumns,
    sortColumnLookup,
    filterColumnLookup,
    columnPositions,
    columnHeaderTabIndexState,
    columnGroupHeaderTabIndexState,
    columnHeaderFocus,
    columnGroupHeaderFocus,
    densityFactor,
    headerGroupingMaxDepth,
    columnMenuState,
    columnVisibility,
    columnGroupsHeaderStructure,
    hasOtherElementInTabSequence,
  });

  return (
    <GridBaseColumnHeaders ref={ref} {...getRootProps(other)}>
      <GridScrollArea scrollDirection="left" />
      <GridColumnHeadersInner isDragging={isDragging} {...getInnerProps()}>
        {getColumnGroupHeaders()}
        {getColumnHeaders()}
      </GridColumnHeadersInner>
      <GridScrollArea scrollDirection="right" />
    </GridBaseColumnHeaders>
  );
});

export default ColumnHeaders;

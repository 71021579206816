const SUBSCRIPTION_DATE = [
  {
    id: 'never',
    label: 'never',
  },
  {
    id: 'weekly',
    label: 'weekly',
  },
  {
    id: 'monthly',
    label: 'monthly',
  },
];

const DATE_FORMATS = [
  {
    id: 'month-first',
    label: 'Month Day, Year',
    value: 'MMM DD, YYYY',
  },
  {
    id: 'day-first',
    label: 'Day/Month/Year',
    value: 'DD/MM/YYYY',
  },
];

const DAYS_OF_WEEK = [
  {
    id: 'monday',
    label: 'Monday',
  },
  {
    id: 'sunday',
    label: 'Sunday',
  },
];

const CURRENCY_GROUPING = [
  {
    id: 'none',
    label: 'none',
  },
  {
    id: 'space',
    label: 'space',
  },
  {
    id: 'comma',
    label: 'comma',
  },
  {
    id: 'apostrophe',
    label: 'apostrophe',
  },
];

const CURRENCY_DECIMAL = [
  {
    id: 'comma',
    label: 'comma',
  },
  {
    id: 'period',
    label: 'period',
  },
];

const NUMBER_FORMATS = {
  grouping: {
    none: undefined,
    space: ' ',
    comma: ',',
    apostrophe: "'",
  },
  decimal: {
    comma: ',',
    period: '.',
  },
};

export {
  CURRENCY_DECIMAL,
  CURRENCY_GROUPING,
  DATE_FORMATS,
  DAYS_OF_WEEK,
  NUMBER_FORMATS,
  SUBSCRIPTION_DATE,
};

import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import styled from '@mui/system/styled';

import googleIcon from '../../assets/icons/google.png';
import microsoftIcon from '../../assets/icons/microsoft.png';
import { supabase } from '../../auth';

import { StyledDivider } from './Styled';

const SocialButton = styled(Button)({
  alignSelf: 'center',
  backgroundColor: 'white',
  borderRadius: '50px',
  padding: '10px 16px',
});

const SocialIcon = styled(Box)({
  width: '21px',
  height: '21px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  marginRight: '15px',
  marginLeft: '6px',
});

export default function SocialSignIn() {
  return (
    <>
      <StyledDivider sx={{ margin: '12px 0' }}>
        or
      </StyledDivider>
      <SocialButton
        sx={{ marginBottom: '12px' }}
        onClick={() => supabase.auth.signInWithOAuth({
          provider: 'azure',
          options: {
            scopes: 'email',
          },
        })}
      >
        <SocialIcon sx={{ backgroundImage: `url(${microsoftIcon})` }} />
        Sign in with Microsoft
      </SocialButton>
      <SocialButton
        onClick={() => supabase.auth.signInWithOAuth({
          provider: 'google',
          options: {
            queryParams: {
              access_type: 'offline',
              prompt: 'consent',
            },
          },
        })}
      >
        <SocialIcon sx={{ width: '22px', backgroundImage: `url(${googleIcon})` }} />
        Sign in with Google
      </SocialButton>
    </>
  );
}

import React, { useState } from 'react';
import { Controller } from 'react-hook-form-mui';
import styled from '@emotion/styled';
import { logoUploadAPI } from 'apis/domains';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import resizeImage from 'utils/resizeImage';

import { CircularProgress, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { IUploaded } from '../../../types/global';

const AvatarBox = styled(Box)(({ theme }: any) => ({
  position: 'absolute',
  left: -130,
  width: 104,
  height: 104,
  [theme.breakpoints.down('lg')]: {
    position: 'static',
    marginRight: '20px',
  },
}));

const CustomAvatar = styled(Avatar)({
  width: '100%',
  height: '100%',
  border: '1px solid #4559A7',
});

interface AvatarUploaderProps {
  image: IUploaded | null;
  setImage: React.Dispatch<React.SetStateAction<IUploaded | null>>;
}

function AvatarUploader({ image, setImage }: AvatarUploaderProps) {
  const [isAvatarEdit, setIsAvatarEdit] = useState(false);
  const [isScaled, setIsScaled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = async (files: FileList | null) => {
    setIsLoading(true);
    if (files && files.length > 0) {
      const imageFile = await resizeImage(files[0]);
      const formData = new FormData();
      formData.append('file', imageFile);

      const response = await logoUploadAPI(formData);
      setImage(response.data);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsScaled(false)}>
      <AvatarBox
        onMouseEnter={() => {
          if (!isScaled) setIsAvatarEdit(true);
        }}
        onMouseLeave={() => {
          if (!isScaled) setIsAvatarEdit(false);
        }}
      >
        {isLoading ? (
          <CircularProgress
            data-testid="avatar_loader"
            size={110}
            thickness={1}
            sx={{ position: 'absolute', top: -3, left: -3 }}
          />
        ) : null}
        <CustomAvatar
          alt="Profile Avatar"
          src={image?.uploadedUrl ?? undefined}
          onLoad={() => {
            setIsLoading(false);
            setIsScaled(false);
          }}
        />
        <Stack
          component="label"
          data-testid="avatar_edit_button"
          onClick={() => setIsScaled(true)}
          sx={{
            position: 'absolute',
            top: 0,
            right: isScaled ? 0 : -14,
            width: isScaled ? 104 : 36,
            height: isScaled ? 104 : 36,
            backgroundColor: isScaled ? '#4559A7' : '#F5F3F1',
            transition: 'all .4s',
            opacity: isAvatarEdit ? 1 : 0,
            visibility: isAvatarEdit ? 'visible' : 'hidden',
            border: '1px solid #4559A7',
            borderRadius: '100%',
            cursor: 'pointer',
          }}
        >
          {isScaled ? (
            <Controller
              name="avatar"
              render={({ field }) => (
                <>
                  <UploadIcon style={{ margin: 'auto', color: 'white' }} />
                  <input
                    type="file"
                    alt="avatar uploader"
                    onChange={async (e) => {
                      handleFileUpload(e.target.files);
                      field.onChange(e);
                    }}
                    accept=".jpg,.png"
                    ref={field.ref}
                    hidden
                  />
                </>
              )}
            />
          ) : (
            <EditIcon style={{ margin: 'auto' }} />
          )}
        </Stack>
      </AvatarBox>
    </ClickAwayListener>
  );
}

export default AvatarUploader;

import React from 'react';

import TextField from '@mui/material/TextField';
import styled from '@mui/system/styled';

const DashboardSelect = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    background: 'transparent',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  '& .MuiFormLabel-root': {
    transform: 'translate(12px, 4.5px) scale(1)',
    color: theme.palette.primary.main,
  },
}));

export default DashboardSelect;

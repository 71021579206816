import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import useEventListener from '@use-it/event-listener';
import dayjs, { Dayjs } from 'dayjs';

import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';
import styled from '@mui/system/styled';
import {
  DataGridPro,
  DataGridProProps,
  GRID_TREE_DATA_GROUPING_FIELD, GridApi,
  gridClasses,
  GridColDef,
  GridColumnVisibilityModel,
  gridExpandedSortedRowEntriesSelector,
  GridFilterModel, GridLogicOperator,
  GridRenderCellParams,
  GridRowId,
  GridSortCellParams,
  GridSortModel,
  gridStringOrNumberComparator,
  GridValidRowModel,
  GridValueFormatterParams,
  useGridSelector
} from '@mui/x-data-grid-pro';
import { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internal/models';

import { SessionContext } from '../../auth';
import usePersistentState from '../../hooks/usePersistentState';
import { HeaderContext } from '../../layouts/main/header/context';
import DashboardContext from '../../pages/DashboardContext';
import { CampaignProduct, DashboardType, ICampaignOrOffer, IGrossRow, IIncrementalRow } from '../../types/global';
import { IProfile, IProfileDomain } from '../../types/users';
import {
  getFullYearFormat,
  makeFormatCurrency,
  makeFormatCurrencyList,
  makeFormatNumber,
  makeFormatNumberList,
  makeFormatPercent,
  makeFormatPercentList,
  useNoLeadingZerosDateFormat
} from '../../utils/format';
import { CustomGridTreeDataGroupingCell, ExpandedRowsContext, ExtraRenderCellParams } from '../DashboardGroupingCell';
import FilterPanel from '../FilterPanel';
import {
  booleanOperators,
  dateOperators,
  listOperators,
  numberOperators,
  stringOperators
} from '../FilterPanel/operators';

import CampaignHeader from './CampaignHeader';
import ColumnHeaders from './ColumnHeaders';
import { downloadExcel } from './downloadExcel';
import EndsCell from './EndsCell';
import FilterButton from './FilterButton';
import NumberCell from './NumberCell';
import SideButtons from './SideButtons';
import { SortedAscendingIcon, SortedDescendingIcon } from './SortIcons';
import StartsCell from './StartsCell';
import TopPanel from './TopPanel';

const controlComparator = (v1: string[], v2: string[], param1: GridSortCellParams, param2: GridSortCellParams) =>
  gridStringOrNumberComparator(v1[0], v2[0], param1, param2);

const makeValueRounder = (digits = 2) => ({ value }: { value: number | undefined }) => (
  typeof value === 'number' ? Number(value.toFixed(digits)) : value
);

const descriptions = {
  offerAttractiveness: 'Number of sessions presented an engagement / Number of sessions submitted (accepted) the engagement',
  testGroup: 'Treatment sessions are engaged with while Control sessions are not.',
  targetedVisits: 'For the time frame selected, the percentage of total sessions that each engagement was presented.',
  engagements: 'Count of sessions that saw an engagement in the Treatment group.' +
    'Count of sessions that were eligible for an engagement but did NOT see it in the Control group.',
  carts: 'Count of sessions in which a cart was created.',
  cartCreationRate: 'Carts / Engagements',
  cartCreationRateDiff: 'Difference in the cart creation rate between treatment and control.',
  cartCreationChange: 'Cart Creation Diff / Control Cart Creation Rate',
  purchases: 'Count of Engagement sessions with purchases.',
  purchaseRate: 'Purchase Count / Offers',
  purchaseRateDiff: 'Difference in the conversion rate between treatment and control.',
  purchaseRateChange: 'Conversion Rate Diff / Control Conversion Rate',
  aov: 'Gross Revenue / Conversions',
  incrementalRevenue: 'Net new revenue attributed to this Metrical engagement.\n' +
    '\n' +
    '(Treatment Engagements x Treatment Conversion Rate x Treatment AOV) - (Treatment Engagements x Control Conversion Rate x Control AOV)',
  grossRevenue: 'Total revenue generated for all sessions.',
  revenuePerVisit: 'Gross Revenue / Visit Count',
  rpvDiff: 'Difference in the Revenue Per Visit between treatment and control.',
  rpvChange: 'RPV Diff / Control Revenue Per Visit',
  revenuePerShopper: 'Gross Revenue / Shopper Count',
  rpsDiff: 'Difference in the Revenue Per Shopper between treatment and control.',
  rpsChange: 'RPS Diff / Control Revenue Per Shopper',
};

const createColumns = (
  renderCellProps: React.RefObject<ExtraRenderCellParams>,
  profile: IProfile,
  isDesktop: boolean,
  dashboardType: DashboardType,
  setFilterModel: (filterMode: GridFilterModel) => void,
  hiddenColumns: string[],
): GridColDef<IIncrementalRow | IGrossRow>[] => [
  {
    field: 'id',
    headerName: 'ID',
    type: 'number',
  },
  {
    field: 'significant',
    headerName: 'Significant',
    type: 'boolean',
    filterOperators: booleanOperators,
  },
  {
    field: 'campaign',
    headerName: 'Campaign',
    flex: 200,
    minWidth: isDesktop ? 200 : 80,
    renderCell: (props: GridRenderCellParams) => (
      <CustomGridTreeDataGroupingCell {...props} extraRef={renderCellProps} depth={0} />
    ),
    renderHeader: (props) => (
      <CampaignHeader {...props} setFilterModel={setFilterModel} hiddenColumns={hiddenColumns} />
    ),
    filterOperators: stringOperators,
    description: 'A group of one or more engagements.',
  },
  {
    field: 'offer',
    headerName: 'Engagement',
    flex: 200,
    minWidth: 150,
    type: 'list',
    valueGetter: ({ row }) => 'offer' in row ? [row.offer, ...row.keywords] : [],
    renderCell: (props: GridRenderCellParams) => (
      <CustomGridTreeDataGroupingCell {...props} extraRef={renderCellProps} depth={1} showKeywords />
    ),
    sortComparator: (v1: string[], v2: string[], param1: GridSortCellParams, param2: GridSortCellParams) =>
      gridStringOrNumberComparator(v1.join(''), v2.join(''), param1, param2),
    filterOperators: listOperators,
  },
  {
    field: 'device',
    headerName: 'Device',
    minWidth: 70,
    flex: 70,
    valueFormatter: ({ value }) => (value ? `${value[0].toUpperCase()}${value.slice(1)}` : ''),
    filterOperators: stringOperators,
  },
  {
    field: 'products',
    headerName: 'Product',
    minWidth: isDesktop ? 100 : 50,
    flex: 100,
    type: 'list',
    valueFormatter: ({ value }) => value.sort().join(''),
    renderCell: ({ value }: GridRenderCellParams) => (
      <>
        {value.sort().map((x: CampaignProduct) => (
          <Chip key={x} label={x} />
        ))}
      </>
    ),
    sortComparator: (v1, v2, param1, param2) =>
      gridStringOrNumberComparator(v1.sort().join(''), v2.sort().join(''), param1, param2),
    filterOperators: listOperators,
    description: 'Metrical product(s) used for each engagement.',
  },
  {
    field: 'starts',
    headerName: 'Starts',
    minWidth: 100,
    flex: 100,
    type: 'date',
    valueGetter: (x) => dayjs(x.value, 'YYYY-MM-DD').toDate(),
    valueFormatter: (params) => {
      const offer = params.id && params.api.getCellValue(params.id, 'offer');
      const device = params.id && params.api.getCellValue(params.id, 'device');
      return (!offer?.length && !device) ? dayjs(params.value).format(getFullYearFormat(profile)) : '';
    },
    filterOperators: dateOperators,
    renderCell: StartsCell,
    description: 'Start date of the engagement.',
  },
  {
    field: 'ends',
    headerName: 'Ends',
    minWidth: 110,
    flex: 110,
    type: 'date',
    valueGetter: (x) => dayjs(x.value, 'YYYY-MM-DD').toDate(),
    valueFormatter: (params) => {
      const offer = params.id && params.api.getCellValue(params.id, 'offer');
      const device = params.id && params.api.getCellValue(params.id, 'device');
      return (!offer?.length && !device) ? dayjs(params.value).format(getFullYearFormat(profile)) : '';
    },
    filterOperators: dateOperators,
    renderCell: EndsCell,
    description: 'End date of the engagement.',
  },
  ...(dashboardType === DashboardType.Incremental ? [] : [
    {
      field: 'shoppers',
      headerName: 'Shoppers',
      minWidth: 70,
      flex: 80,
      type: 'number',
      filterOperators: numberOperators,
      valueFormatter: makeFormatNumber(profile),
      renderCell: NumberCell,
    },
    {
      field: 'visits',
      headerName: 'Visits',
      minWidth: 70,
      flex: 80,
      type: 'number',
      filterOperators: numberOperators,
      valueFormatter: makeFormatNumber(profile),
      renderCell: NumberCell,
    },
  ]),
  {
    field: 'offerAttractiveness',
    headerName: 'Eng. Attrac\u00ADtiveness',
    minWidth: 70,
    flex: 100,
    type: 'number',
    filterOperators: numberOperators,
    valueGetter: makeValueRounder(),
    valueFormatter: makeFormatPercent(profile),
    renderHeader: ({ colDef: { description } }) => (
      <Tooltip title={description}>
        <Box
          className={gridClasses.columnHeaderTitle}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginBottom: '6px',
          }}
        >
          {'Eng. '}
          <Box component="span" sx={{ display: 'inline-block' }}>
            Attrac&shy;tiveness
          </Box>
        </Box>
      </Tooltip>
    ),
    renderCell: NumberCell,
    description: descriptions.offerAttractiveness,
  },
  ...(dashboardType === DashboardType.Incremental ? [
    {
      field: 'testGroup',
      headerName: 'Test Group',
      minWidth: 72,
      flex: 75,
      type: 'list',
      valueFormatter: (x: GridValueFormatterParams<string[]>) => x.value.join('\n'),
      sortable: false,
      filterable: false,
      description: descriptions.testGroup,
    },
    {
      field: 'targetedVisits',
      headerName: 'Targeted %\u00A0of Visits',
      minWidth: 63,
      flex: 75,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatPercentList(profile),
      sortComparator: controlComparator,
      renderCell: NumberCell,
      description: descriptions.targetedVisits,
    },
    {
      field: 'offers',
      headerName: 'Engage\u00ADments',
      minWidth: 70,
      flex: 80,
      type: 'number',
      filterOperators: numberOperators,
      valueFormatter: makeFormatNumberList(profile),
      sortComparator: controlComparator,
      renderCell: NumberCell,
      description: descriptions.engagements,
    },
    {
      field: 'carts',
      headerName: 'Carts',
      minWidth: 70,
      flex: 80,
      type: 'number',
      filterOperators: numberOperators,
      valueFormatter: makeFormatNumberList(profile),
      cellClassName: 'cell-gray',
      sortComparator: controlComparator,
      renderCell: NumberCell,
      description: descriptions.carts,
    },
    {
      field: 'cartCreationRate',
      headerName: 'Cart Creation Rate',
      minWidth: 70,
      flex: 70,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatPercentList(profile),
      sortComparator: controlComparator,
      renderCell: NumberCell,
      description: descriptions.cartCreationRate,
    },
    {
      field: 'cartCreationRateDiff',
      headerName: 'Cart Creation Rate Diff',
      minWidth: 70,
      flex: 70,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatPercent(profile),
      renderCell: NumberCell,
      description: descriptions.cartCreationRateDiff,
    },
    {
      field: 'cartCreationChange',
      headerName: 'Cart Creation %\u00A0Change',
      minWidth: 70,
      flex: 70,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatPercent(profile),
      renderCell: NumberCell,
      description: descriptions.cartCreationChange,
    },
    {
      field: 'purchases',
      headerName: 'Con\u00ADversions',
      minWidth: 70,
      flex: 80,
      type: 'number',
      filterOperators: numberOperators,
      valueFormatter: makeFormatNumberList(profile),
      cellClassName: 'cell-gray',
      sortComparator: controlComparator,
      renderCell: NumberCell,
      description: descriptions.purchases,
    },
    {
      field: 'purchaseRate',
      headerName: 'Con\u00ADversion Rate',
      minWidth: 60,
      flex: 70,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatPercentList(profile),
      sortComparator: controlComparator,
      renderCell: NumberCell,
      description: descriptions.purchaseRate,
    },
    {
      field: 'purchaseRateDiff',
      headerName: 'Con\u00ADversion Rate Diff',
      minWidth: 60,
      flex: 70,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatPercent(profile),
      renderCell: NumberCell,
      description: descriptions.purchaseRateDiff,
    },
    {
      field: 'conversionChange',
      headerName: 'Conversion Rate %\u00A0Change',
      minWidth: 70,
      flex: 90,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatPercent(profile),
      renderCell: NumberCell,
      description: descriptions.purchaseRateChange,
    },
    {
      field: 'aov',
      headerName: 'AOV',
      minWidth: 70,
      flex: 90,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatCurrencyList(profile),
      sortComparator: controlComparator,
      renderCell: NumberCell,
      description: descriptions.aov,
    },
    {
      field: 'incrementalRevenue',
      headerName: 'Incre\u00ADmental Revenue',
      minWidth: 85,
      flex: 90,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(0),
      valueFormatter: makeFormatCurrency(profile),
      renderCell: NumberCell,
      description: descriptions.incrementalRevenue,
    },
    {
      field: 'grossRevenue',
      headerName: 'Gross revenue',
      minWidth: 85,
      flex: 90,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(0),
      valueFormatter: makeFormatCurrencyList(profile, 0),
      renderCell: NumberCell,
      description: descriptions.grossRevenue,
    },
    {
      field: 'revenuePerVisit',
      headerName: 'Revenue per visit',
      minWidth: 70,
      flex: 90,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatCurrencyList(profile),
      renderCell: NumberCell,
      description: descriptions.revenuePerVisit,
    },
    {
      field: 'rpvDiff',
      headerName: 'RPV Diff',
      minWidth: 60,
      flex: 70,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatCurrency(profile, 2),
      renderCell: NumberCell,
      description: descriptions.rpvDiff,
    },
    {
      field: 'rpvChange',
      headerName: 'RPV % Change',
      minWidth: 70,
      flex: 90,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatPercent(profile),
      renderCell: NumberCell,
      description: descriptions.rpvChange,
    },
    {
      field: 'revenuePerShopper',
      headerName: 'Revenue per shopper',
      minWidth: 80,
      flex: 90,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatCurrencyList(profile, 2),
      renderCell: NumberCell,
      description: descriptions.revenuePerShopper,
    },
    {
      field: 'rpsDiff',
      headerName: 'RPS Diff',
      minWidth: 60,
      flex: 70,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatCurrency(profile, 2),
      renderCell: NumberCell,
      description: descriptions.rpsDiff,
    },
    {
      field: 'rpsChange',
      headerName: 'RPS % Change',
      minWidth: 75,
      flex: 90,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatPercent(profile),
      renderCell: NumberCell,
      description: descriptions.rpsChange,
    },
  ] : [
    {
      field: 'targetedVisits',
      headerName: 'Targeted %\u00A0of Visits',
      minWidth: 63,
      flex: 75,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatPercent(profile),
      renderCell: NumberCell,
      description: descriptions.targetedVisits,
    },
    {
      field: 'offers',
      headerName: 'Engage\u00ADments',
      minWidth: 70,
      flex: 80,
      type: 'number',
      filterOperators: numberOperators,
      valueFormatter: makeFormatNumber(profile),
      renderCell: NumberCell,
      description: descriptions.engagements,
    },
    {
      field: 'carts',
      headerName: 'Carts',
      minWidth: 70,
      flex: 80,
      type: 'number',
      filterOperators: numberOperators,
      valueFormatter: makeFormatNumber(profile),
      cellClassName: 'cell-gray',
      renderCell: NumberCell,
      description: descriptions.carts,
    },
    {
      field: 'cartCreationRate',
      headerName: 'Cart Creation Rate',
      minWidth: 70,
      flex: 70,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatPercent(profile),
      renderCell: NumberCell,
      description: descriptions.cartCreationRate,
    },
    {
      field: 'purchases',
      headerName: 'Con\u00ADversions',
      minWidth: 70,
      flex: 80,
      type: 'number',
      filterOperators: numberOperators,
      valueFormatter: makeFormatNumber(profile),
      cellClassName: 'cell-gray',
      renderCell: NumberCell,
      description: descriptions.purchases,
    },
    {
      field: 'purchaseRate',
      headerName: 'Con\u00ADversion Rate',
      minWidth: 60,
      flex: 70,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatPercent(profile),
      renderCell: NumberCell,
      description: descriptions.purchaseRate,
    },
    {
      field: 'aov',
      headerName: 'AOV',
      minWidth: 70,
      flex: 90,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatCurrency(profile, 2),
      renderCell: NumberCell,
      description: descriptions.aov,
    },
    {
      field: 'grossRevenue',
      headerName: 'Gross revenue',
      minWidth: 85,
      flex: 90,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(0),
      valueFormatter: makeFormatCurrency(profile),
      renderCell: NumberCell,
      description: descriptions.grossRevenue,
    },
    {
      field: 'revenuePerVisit',
      headerName: 'Revenue per visit',
      minWidth: 70,
      flex: 90,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatCurrency(profile, 2),
      renderCell: NumberCell,
      description: descriptions.revenuePerVisit,
    },
    {
      field: 'revenuePerShopper',
      headerName: 'Revenue per shopper',
      minWidth: 80,
      flex: 90,
      type: 'number',
      filterOperators: numberOperators,
      valueGetter: makeValueRounder(),
      valueFormatter: makeFormatCurrency(profile, 2),
      renderCell: NumberCell,
      description: descriptions.revenuePerShopper,
    },
  ]),
];

const StyledDataGrid = styled(DataGridPro<IIncrementalRow | IGrossRow>)(() => ({
  '& .MuiDataGrid-columnHeaderTitle': {
    whiteSpace: 'normal',
    lineHeight: '15px',
    maxHeight: '51px',
  },
  '& .offer-row': {
    background: '#F8F8F8',
  },
  '& .device-row': {
    background: '#F5F3F1',
  },
  '& .hovered-trend-row': {
    background: '#bdbdbd',
  },
  '& .MuiDataGrid-row': {
    marginBottom: '-1px',
  },
  '& .cell-gray': {
    color: 'rgb(97 97 97 / 80%)',
  },
}));

const groupingColDef: DataGridProProps['groupingColDef'] = {
  headerName: '',
  renderCell: () => null,
};

function EmptyComponent() {
  return null;
}

interface DashboardTableProps<R extends IIncrementalRow | IGrossRow> {
  rows: R[] | null;
  selectedOffers: R[] | null;
  setSelectedOffers: React.Dispatch<React.SetStateAction<R[] | null>>;
  filteredOffers: ICampaignOrOffer[];
  filterModel: GridFilterModel;
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>;
  dashboardType: R extends IIncrementalRow ? DashboardType.Incremental : DashboardType.Gross;
  dateRange: NonEmptyDateRange<Dayjs> | null;
  apiRef: React.MutableRefObject<GridApi>;
  setDeviceColumnHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

function DashboardTable<R extends IIncrementalRow | IGrossRow>(
  {
    rows, selectedOffers, setSelectedOffers, filteredOffers, filterModel, setFilterModel, dashboardType,
    dateRange, apiRef, setDeviceColumnHidden,
  }: DashboardTableProps<R>,
) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { hoveredTrend, setHoveredTrend } = useContext(DashboardContext);
  const getRowClassName = useCallback(({ row }: { row: any }) => {
    const rowType = (() => {
      if ('device' in row) {
        return 'device-row';
      }
      if ('offer' in row) {
        return 'offer-row';
      }
      return '';
    })();
    return `${(row.campaign === hoveredTrend || `OFFER___${row.offer}` === hoveredTrend) ? 'hovered-trend-row ' : ''}${rowType}`;
  }, [hoveredTrend]);
  const { profile } = useContext(SessionContext) as { profile: IProfile };
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'campaign',
      sort: 'asc',
    },
  ]);
  const [filterPanelAnchor, setFilterPanelAnchor] = useState<HTMLElement | null>(null);
  const [filterPanelColumn, setFilterPanelColumn] = useState<string | null>(null);
  const getTreeDataPath = useCallback((row: GridValidRowModel) => row.path, []);
  const [selectEnabled, setSelectEnabled] = useState(false);
  useEffect(() => {
    setSelectedOffers(selectEnabled ? [] : null);
  }, [selectEnabled]);
  const isTreeData = !filteredOffers.some((x) => 'offer' in x);
  useEffect(() => {
    if (!isTreeData) {
      setSortModel([
        { field: 'campaign', sort: 'asc' },
        { field: 'offer', sort: 'asc' },
        { field: 'device', sort: 'asc' },
      ]);
    }
  }, [isTreeData]);
  const cellPropsRef = useRef<any>(null);
  cellPropsRef.current = {
    selectedOffers,
    setSelectedOffers,
    selectEnabled,
    isTreeData,
  };
  const [hiddenColumns, setHiddenColumns] = usePersistentState<string[]>('dashboard-hidden-columns', []);
  const [expandedRows, setExpandedRows] = useState<Record<GridRowId, boolean>>({});
  const expandedRowsContextValue = useMemo(
    () => ({
      rows: expandedRows,
      setRows: setExpandedRows,
    }),
    [expandedRows]
  );
  useEffect(() => {
    setExpandedRows({});
  }, [rows]);
  const [allRows, setAllRows] = useState(false);
  const columnVisibilityModel: GridColumnVisibilityModel = useMemo(() => {
    const { offer, device } = (() => {
      if (apiRef.current?.state) {
        const gridRows = useGridSelector(apiRef, gridExpandedSortedRowEntriesSelector);
        const showOffers = !isTreeData || gridRows.some(({ id, model }) => expandedRows[id] && !('offer' in model));
        const showDevices = !isTreeData || gridRows.some(({ id, model }) => expandedRows[id] && 'offer' in model);
        return { offer: showOffers, device: showDevices };
      }
      return { offer: false, device: false };
    })();
    return {
      [GRID_TREE_DATA_GROUPING_FIELD]: false,
      id: false,
      significant: false,
      offer,
      device,
      starts: isDesktop,
      ends: isDesktop,
      ...(allRows ? Object.fromEntries(hiddenColumns.map((x) => [x, false])) : {}),
      ...Object.fromEntries(
        [
          'offerAttractiveness',
          'shoppers',
          'visits',
          'testGroup',
          'targetedVisits',
          'offers',
          'carts',
          'cartCreationRate',
          'cartCreationRateDiff',
          'purchases',
          'purchaseRate',
          'purchaseRateDiff',
          'aov',
          ...(dashboardType === DashboardType.Incremental ? [
            'grossRevenue',
            'revenuePerVisit',
            'revenuePerShopper',
          ] : []),
          'rpvDiff',
          'rpvChange',
          'rpsDiff',
          'rpsChange',
        ].map((x) => [x, isDesktop && allRows && !hiddenColumns.includes(x)])
      ),
    };
  }, [isDesktop, expandedRows, isTreeData, hiddenColumns, dashboardType, allRows]);
  useEffect(() => {
    setDeviceColumnHidden(!columnVisibilityModel.device);
  }, [columnVisibilityModel.device]);
  const columns = useMemo(() => (
    createColumns(cellPropsRef, profile as IProfile, isDesktop, dashboardType, setFilterModel, hiddenColumns)
  ), [profile, isDesktop, dashboardType, hoveredTrend, hiddenColumns]);
  const filterPanelColumnType = useMemo(
    () => columns.find((x) => x.field === filterPanelColumn)?.type,
    [filterPanelColumn]
  );
  const tableContainerRef = useRef<HTMLElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  useLayoutEffect(() => {
    if (isFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, [isFullscreen]);
  useEventListener('fullscreenchange', () => {
    if (!document.fullscreenElement) {
      setIsFullscreen(false);
    }
  }, document.documentElement);
  useEffect(() => {
    setExpandedRows({});
  }, [isFullscreen]);
  const noLeadingZerosDateFormat = useNoLeadingZerosDateFormat();
  const rowMouseEnter: React.MouseEventHandler<HTMLDivElement> = useCallback((event) => {
    const { id } = event.currentTarget.dataset;
    const row = rows?.find((x) => x.id === id);
    if (row && !('device' in row)) {
      setHoveredTrend(row.offer ? `OFFER___${row.offer}` : row.campaign);
    }
  }, [rows, setHoveredTrend]);
  const rowMouseLeave = useCallback(() => {
    setHoveredTrend(null);
  }, [setHoveredTrend]);
  const { domainId } = useContext(HeaderContext);
  const { name: domainName } = profile.organizations
    .flatMap((x) => x.domains)
    .find((x) => x.id === domainId) as IProfileDomain;
  const ignoreSortRef = useRef(false);
  const onColumnHeaderClick = useCallback((_: any, event: SyntheticEvent) => {
    if (!(event.target as HTMLElement).closest('button')) {
      ignoreSortRef.current = true;
    }
  }, []);
  const onSortModelChange = useCallback((model: GridSortModel) => {
    if (ignoreSortRef.current) {
      ignoreSortRef.current = false;
    } else {
      setSortModel(model);
    }
  }, []);
  if (!rows) {
    return null;
  }
  const sideButtons = (
    <SideButtons
      allRows={allRows}
      setAllRows={setAllRows}
      hiddenColumns={hiddenColumns}
      setHiddenColumns={setHiddenColumns}
      columns={columns}
      isFullscreen={isFullscreen}
      setIsFullscreen={setIsFullscreen}
      onDownload={(isAll) => downloadExcel(apiRef, isAll, noLeadingZerosDateFormat, dateRange, domainName)}
    />
  );
  const content = (
    <Box
      sx={{
        ...(isFullscreen ? {
          width: '100%',
          height: '100%',
          background: theme.palette.secondary.lighter,
          padding: '8px',
        } : {
          width: '100vw',
          margin: '0 -24px 16px',
          height: '448px',
        }),
        [theme.breakpoints.down('lg')]: {
          margin: '0 -16px 16px',
          width: '100vw',
        },
      }}
      ref={tableContainerRef}
    >
      <Box
        sx={{
          height: isFullscreen ? 'calc(100% - 48px)' : 400,
          display: 'flex',
          width: '100%',
          [theme.breakpoints.down('lg')]: {
            paddingRight: 0,
          },
        }}
      >
        <Box sx={{ flex: 1, width: '100%' }}>
          <TopPanel
            selectEnabled={selectEnabled}
            setSelectEnabled={setSelectEnabled}
            filtersEnabled={filterModel.items.length > 0}
            onClearFilters={() => setFilterModel({
              items: [],
              logicOperator: GridLogicOperator.And,
            })}
          >
            {sideButtons}
          </TopPanel>
          <ExpandedRowsContext.Provider value={expandedRowsContextValue}>
            <StyledDataGrid
              apiRef={apiRef}
              treeData={isTreeData}
              getTreeDataPath={getTreeDataPath}
              groupingColDef={groupingColDef}
              rows={rows}
              columns={columns}
              slots={{
                columnHeaders: ColumnHeaders,
                columnSortedAscendingIcon: SortedAscendingIcon,
                columnSortedDescendingIcon: SortedDescendingIcon,
                columnUnsortedIcon: SortedDescendingIcon,
                columnHeaderFilterIconButton: FilterButton,
                filterPanel: FilterPanel,
                footer: EmptyComponent,
              }}
              slotProps={{
                panel: {
                  anchorEl: filterPanelAnchor,
                },
                columnHeaderFilterIconButton: {
                  setAnchor: setFilterPanelAnchor,
                  setField: setFilterPanelColumn,
                },
                filterPanel: {
                  filterModel,
                  setFilterModel,
                  column: filterPanelColumn as string,
                  columnType: filterPanelColumnType,
                },
                row: {
                  onMouseEnter: rowMouseEnter,
                  onMouseLeave: rowMouseLeave,
                },
              }}
              onColumnHeaderClick={onColumnHeaderClick}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              filterModel={filterModel}
              columnVisibilityModel={columnVisibilityModel}
              disableRowSelectionOnClick
              disableColumnMenu
              disableColumnResize
              disableColumnReorder
              columnHeaderHeight={92}
              rowHeight={36}
              getRowClassName={getRowClassName}
            />
          </ExpandedRowsContext.Provider>
        </Box>
      </Box>
    </Box>
  );
  if (isFullscreen) {
    return (
      <Modal open>
        {content}
      </Modal>
    );
  }
  return content;
}

const genericMemo: <T>(component: T) => T = React.memo;

export default genericMemo(DashboardTable);

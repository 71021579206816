import React, { useState } from 'react';
import AddUserFormGroup from 'modules/screens/roles/AddUserForm/AddUserForm';
import UserTableForm from 'modules/screens/roles/UserTableForm';

import { Stack } from '@mui/system';

import { listUsers } from '../../apis/users';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import { IRolesUser } from '../../types/users';

export default function UserRolesPage() {
  const [users, setUsers] = useState<IRolesUser[] | null>(null);
  useAsyncEffect(async () => {
    const { data } = await listUsers();
    setUsers(data);
  }, []);
  if (!users) {
    return null;
  }
  return (
    <Stack direction="column" spacing={6} sx={{ paddingTop: 6 }}>
      <AddUserFormGroup
        onAddUser={(newUser) => {
          setUsers([...users, newUser]);
        }}
      />
      <UserTableForm users={users} updateUsers={setUsers} />
    </Stack>
  );
}

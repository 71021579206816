import { Theme } from '@mui/material/styles';
import { Components } from '@mui/material/styles/components';

const dataGridOverrides: Components<Theme> = {
  MuiDataGrid: {
    styleOverrides: {
      root: {
        background: 'white',
        border: 'none',
        borderRadius: 0,
        filter: 'drop-shadow(2px 5px 16px rgba(0, 0, 0, 0.1))',
        fontSize: '12px',
        color: '#616161',
        fontFeatureSettings: "'tnum' on, 'lnum' on",
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
          width: '100%',
          marginLeft: 5,
          marginBottom: '-6px',
          '&:last-child': {
            marginBottom: 20,
          },
        },
      },
      cell: ({ theme }) => ({
        padding: '0 8px',
        '&:first-of-type': {
          paddingLeft: '24px',
        },
        '&:last-of-type': {
          paddingRight: '24px',
        },
        [theme.breakpoints.down('lg')]: {
          '&:first-of-type': {
            paddingLeft: '8px',
          },
          '&:last-of-type': {
            paddingRight: '8px',
          },
        },
        '&:focus, &:focus-within': {
          outline: 'none',
        },
        '& .MuiChip-root': {
          fontSize: '10px',
          fontWeight: 800,
          fontVariant: 'all-small-caps',
          color: 'white',
          backgroundColor: theme.palette.secondary.dark,
          height: '12px',
          '&:not(:last-of-type)': {
            marginRight: '4px',
          },
          '& .MuiChip-label': {
            paddingLeft: '6px',
            paddingRight: '6px',
          },
          [theme.breakpoints.down('lg')]: {
            background: 'inherit',
            color: 'inherit',
            font: 'inherit',
            textTransform: 'uppercase',
            '&:not(:last-of-type)': {
              marginRight: '2px',
              '&::after': {
                content: '","',
              },
            },
            '& .MuiChip-label': {
              padding: 0,
            },
          },
        },
        '&.MuiDataGrid-cell--textRight': {
          textAlign: 'right',
        },
        '& .MuiFormControlLabel-root': {
          margin: 0,
        },
      }),
      cellContent: {
        whiteSpace: 'pre',
        lineHeight: '14px',
      },
      columnHeader: ({ theme }) => ({
        height: 80,
        color: theme.palette.primary.main,
        fontVariant: 'all-small-caps',
        padding: '10px 3px',
        '&:first-of-type': {
          paddingLeft: '19px',
        },
        '&:last-of-type': {
          paddingRight: '19px',
        },
        [theme.breakpoints.down('lg')]: {
          '&:first-of-type': {
            paddingLeft: '11px',
          },
          '&:last-of-type': {
            paddingRight: '11px',
          },
        },
        '&:focus, &:focus-within': {
          outline: 'none',
        },
        '&:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon': {
          opacity: 1,
        },
        '&:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-sortIcon': {
          opacity: 1,
        },
        '&.MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainerContent': {
          justifyContent: 'flex-end',
          textAlign: 'right',
          marginLeft: 0,
          marginRight: 5,
        },
        '&.MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': {
          flexDirection: 'row',
          justifyContent: 'flex-end',
        },
        '&.MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainerContent': {
          marginLeft: 0,
          textAlign: 'center',
        },
      }),
      sortIcon: {
        opacity: 1,
      },
      columnHeaderTitleContainer: {
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        marginTop: 'auto',
      },
      columnHeaderTitle: {
        paddingBottom: '6px',
        fontWeight: 400,
      },
      iconButtonContainer: {
        width: 'auto',
        visibility: 'visible',
      },
      paper: ({ theme }) => ({
        boxShadow: 'none',
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.secondary.lighter,
        minWidth: 200,
      }),
      row: {
        borderBottom: '1px solid transparent',
        boxShadow: 'inset 0px -0.5px 0px #E6E8F0',
        '&.MuiDataGrid-row--lastVisible': {
          borderBottom: 'none',
          boxShadow: 'none',
        },
        '&:hover': {
          backgroundColor: 'rgba(116,130,186,0.3)',
        },
      },
      columnHeaders: {
        borderBottom: '1px solid transparent',
        boxShadow: 'inset 0px -0.5px 0px #E6E8F0',
      },
      editInputCell: ({ theme }) => ({
        '&.Mui-error': {
          color: theme.palette.error.main,
        },
      }),
    },
  },
};

export default dataGridOverrides;

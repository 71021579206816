import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import ExcelJS from 'exceljs';

import {
  GRID_TREE_DATA_GROUPING_FIELD,
  gridColumnFieldsSelector,
  gridColumnLookupSelector,
  gridExpandedSortedRowIdsSelector,
  gridSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector
} from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internal/models';

/* eslint-disable no-param-reassign */

const excelColumnStyle: Record<string, any> = {
  campaign: { width: 15 },
  offer: { width: 15 },
  starts: {
    width: 12,
  },
  ends: {
    width: 12,
  },
  incrementalRevenue: {
    width: 20,
    numFmt: '$#,###',
  },
  offerAttractiveness: {
    width: 20,
    numFmt: '0.00%',
  },
  targetedVisits: {
    width: 20,
    numFmt: '0.00%',
  },
  offers: {
    width: 10,
    numFmt: '#,###',
  },
  carts: {
    width: 10,
    numFmt: '#,###',
  },
  cartCreationRate: {
    width: 20,
    numFmt: '0.00%',
  },
  cartCreationRateDiff: {
    width: 20,
    numFmt: '0.00%',
  },
  cartCreationChange: {
    width: 22,
    numFmt: '0.00%',
  },
  purchases: {
    width: 10,
    numFmt: '#,###',
  },
  purchaseRate: {
    width: 15,
    numFmt: '0.00%',
  },
  purchaseRateDiff: {
    width: 20,
    numFmt: '0.00%',
  },
  conversionChange: {
    width: 22,
    numFmt: '0.00%',
  },
  aov: {
    width: 10,
    numFmt: '$#,##0.00',
  },
  grossRevenue: {
    width: 15,
    numFmt: '$#,###',
  },
  revenuePerVisit: {
    width: 16,
    numFmt: '$#,##0.00',
  },
  rpvDiff: {
    width: 12,
    numFmt: '$#,##0.00',
  },
  rpvChange: {
    width: 12,
    numFmt: '0.00%',
  },
  revenuePerShopper: {
    width: 20,
    numFmt: '$#,##0.00',
  },
  rpsDiff: {
    width: 12,
    numFmt: '$#,##0.00',
  },
  rpsChange: {
    width: 12,
    numFmt: '0.00%',
  },
};
const tupleFields = [
  'testGroup', 'targetedVisits', 'offers', 'carts', 'cartCreationRate', 'purchases', 'purchaseRate', 'aov',
  'grossRevenue', 'revenuePerVisit', 'revenuePerShopper',
];
const treatmentOnlyFields = [
  'cartCreationRateDiff', 'cartCreationChange', 'purchaseRateDiff', 'conversionChange', 'incrementalRevenue',
  'rpvDiff', 'rpvChange', 'rpsDiff', 'rpsChange', 'offerAttractiveness',
];

export const downloadExcel = async (
  apiRef: React.MutableRefObject<GridApiPro>,
  isAll: boolean,
  noLeadingZerosDateFormat: string,
  dateRange: NonEmptyDateRange<Dayjs> | null,
  domainName: string,
) => {
  if (!dateRange) {
    return;
  }
  const filteredSortedRowIds = (isAll ? gridSortedRowIdsSelector : gridExpandedSortedRowIdsSelector)(apiRef);
  const visibleColumnsField = (isAll ? gridColumnFieldsSelector : gridVisibleColumnFieldsSelector)(apiRef)
    .filter((x) => x !== 'id' && x !== GRID_TREE_DATA_GROUPING_FIELD);
  const workbook = new ExcelJS.Workbook();
  enum SheetType { All, Campaign, Offer, Device }
  const fillSheet = (sheet: ExcelJS.Worksheet, type: SheetType) => {
    const columnDefinitions = gridColumnLookupSelector(apiRef);
    const filteredColumns = visibleColumnsField.filter((column) => {
      if (type === SheetType.Campaign) {
        return column !== 'offer' && column !== 'device';
      }
      if (type === SheetType.Offer) {
        return column !== 'device';
      }
      return true;
    });
    sheet.columns = filteredColumns.map((column) => {
      const { width, ...rest } = excelColumnStyle[column] ?? { width: 10 };
      return ({
        header: (columnDefinitions[column].headerName || '').replaceAll('\u00AD', ''),
        width,
        style: rest,
      })
    });
    filteredSortedRowIds.forEach((id) => {
      const { value: offer }: { value?: string[] } = apiRef.current.getCellParams(id, 'offer');
      const { value: device } = apiRef.current.getCellParams(id, 'device');
      if (
        (type === SheetType.Campaign && (offer?.length || device))
        || (type === SheetType.Offer && (!offer?.length || device))
        || (type === SheetType.Device && (!offer?.length || !device))
      ) {
        return;
      }
      const makeGetValue = (groupIndex: number | null) => (field: string) => {
        const { row } = apiRef.current.getCellParams(id, field);
        const value = row[field];
        if (field === 'starts' || field === 'ends') {
          return dayjs(value).format(noLeadingZerosDateFormat);
        }
        if (groupIndex === 0 && treatmentOnlyFields.includes(field)) {
          return '';
        }
        if (field === 'products') {
          return (value as string[]).map((x) => x.toUpperCase()).join(', ');
        }
        const groupValue = (Array.isArray(value) && groupIndex !== null) ? value[groupIndex] : value;
        if (excelColumnStyle[field]?.numFmt?.includes('%')) {
          return groupValue == null ? '' : groupValue / 100;
        }
        return groupValue;
      };
      if (filteredColumns.some((x) => tupleFields.includes(x))) {
        const getControl = makeGetValue(0);
        const getTreatment = makeGetValue(1);
        sheet.addRow(filteredColumns.map(getControl));
        sheet.addRow(filteredColumns.map(getTreatment));
      } else {
        const getValue = makeGetValue(null);
        sheet.addRow(filteredColumns.map(getValue));
      }
    });
  };
  if (isAll) {
    fillSheet(workbook.addWorksheet('Campaigns'), SheetType.Campaign);
    fillSheet(workbook.addWorksheet('Offers'), SheetType.Offer);
    fillSheet(workbook.addWorksheet('Devices'), SheetType.Device);
  } else {
    fillSheet(workbook.addWorksheet('Metrical Dashboard'), SheetType.All);
  }
  const buffer = await workbook.xlsx.writeBuffer();
  const a = document.createElement('a');
  a.href = URL.createObjectURL(new Blob([buffer]));
  const now = dayjs();
  const formattedRange = `${dateRange[0].format('YYYYMMDD')}-${dateRange[1].format('YYYYMMDD')}`;
  a.download = `${domainName} - ${formattedRange} - ${now.format('YYYYMMDD_HHmm')}.xlsx`;
  a.click();
};

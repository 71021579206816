import React, { useState } from 'react';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';

import { supabase } from '../../auth';
import SocialSignIn from '../../components/Auth/SocialSignIn';
import { ErrorMessage, StyledDivider, StyledTypography } from '../../components/Auth/Styled';
import PasswordField from '../../components/Common/PasswordField';
import paths from '../../routes/paths';

interface RegisterForm {
  email: string;
  password: string;
  repeatPassword: string;
}

const RegisteredTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '14px',
  lineHeight: '18px',
  margin: '0 12px 16px',
  display: 'flex',
  alignItems: 'baseline',
}));

export default function Register() {
  const passwordMessage = 'Minimum 8 characters, including 1 lower case letter, 1 upper case letter and 1 number';
  const formContext = useForm<RegisterForm>({
    resolver: yupResolver(yup.object().shape({
      email: yup.string().required().email('Invalid email address'),
      password: yup.string().required().min(8, passwordMessage)
        .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, passwordMessage),
      repeatPassword: yup.string().required('Repeat password is a required field')
        .oneOf([yup.ref('password'), ''], 'Passwords must match')
    })),
  });
  const { handleSubmit } = formContext;
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [resent, setResent] = useState<null | 'success' | 'failure'>(null);
  if (registered) {
    return (
      <>
        <RegisteredTypography>
          Email has been sent to {formContext.getValues().email}.
        </RegisteredTypography>
        <RegisteredTypography>
          If you do not see it in your inbox, check your Spam folder or contact your admin.
        </RegisteredTypography>
        <RegisteredTypography>
          Please open this email and click on the activation link to complete your registration.
        </RegisteredTypography>
        <RegisteredTypography>
          {'To resend the email please '}
          <Button
            size="small"
            sx={{
              padding: '0',
              minWidth: '0',
              marginLeft: '4px',
            }}
            onClick={async () => {
              setResent(null);
              const { error } = await supabase.auth.resend({ email: formContext.getValues().email, type: 'signup' });
              setResent(error ? 'failure' : 'success');
            }}
          >
            click here
          </Button>
          .
        </RegisteredTypography>
        {resent && (
          <RegisteredTypography>
            {resent === 'success' ? 'Email has been resent.' : 'Failed to resend an email. Try again in a few minutes.'}
          </RegisteredTypography>
        )}
        <Button
          size="small"
          sx={{
            alignSelf: 'flex-start',
            padding: '0 12px',
          }}
          component={Link}
          to={paths.auth.login}
        >
          Go to login page
        </Button>
      </>
    );
  }
  const onSubmit = async ({ email, password }: RegisterForm) => {
    setAlreadyExists(false);
    const { data } = await supabase.auth.signUp({ email, password });
    if (data.user?.identities?.length === 0) {
      setAlreadyExists(true);
    } else {
      setRegistered(true);
    }
  };
  return (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(onSubmit)}
      FormProps={{ style: { display: 'flex', flexDirection: 'column' } }}
    >
      {alreadyExists && (
        <ErrorMessage>
          User already exists.
        </ErrorMessage>
      )}
      <TextFieldElement label="Email" name="email" sx={{ marginBottom: '12px' }} />
      <PasswordField label="Password" name="password" sx={{ marginBottom: '12px' }} />
      <PasswordField label="Repeat password" name="repeatPassword" sx={{ marginBottom: '6px' }} />
      <Button variant="contained" sx={{ alignSelf: 'flex-end' }} type="submit">
        Register
      </Button>
      <SocialSignIn />
      <StyledTypography
        sx={{
          marginTop: '44px',
          marginBottom: '22px',
        }}
      >
        By registering, you are indicating that you agree to the Terms and Conditions.
      </StyledTypography>
      <StyledDivider />
      <StyledTypography
        sx={{
          marginTop: '12px',
        }}
      >
        Already have an account?
      </StyledTypography>
      <Button
        size="small"
        sx={{
          alignSelf: 'flex-start',
          padding: '0 12px',
          marginTop: '6px',
        }}
        component={Link}
        to={paths.auth.login}
      >
        Log in
      </Button>
    </FormContainer>
  );
}

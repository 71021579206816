import React, { useContext, useEffect, useState } from 'react';
import { CheckboxElement, FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomModal } from 'components/CustomModal';
import { FormSelect } from 'components/Forms/FormSelect';
import { validateEmail } from 'utils/global';
import * as yup from 'yup';

import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { createUser } from '../../../../apis/users';
import { SessionContext } from '../../../../auth';
import { IProfile, IProfileDomain, IProfileOrganization, IRolesUser } from '../../../../types/users';

const StyledStack = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledTextField = styled(TextFieldElement)({
  flex: 1,
  position: 'relative',
});

type UserFormType = {
  firstName: string;
  lastName: string;
  email: string;
  reports: boolean;
  admin: boolean;
  metrical: boolean;
  organizations: number[];
  domains: number[];
};

interface AddUserFormGroupProps {
  onAddUser: (newUser: IRolesUser) => void;
}

export default function AddUserFormGroup({ onAddUser }: AddUserFormGroupProps) {
  const { profile } = useContext(SessionContext) as { profile: IProfile };
  const isMetrical = profile.organizations.some((x) => x.master);
  const formContext = useForm<UserFormType>({
    defaultValues: {
      reports: false,
      admin: false,
      metrical: false,
      organizations: [],
      domains: [],
    },
    resolver: yupResolver(yup.object().shape({
      firstName: yup.string().required('This field is required'),
      lastName: yup.string().required('This field is required'),
      email: yup.string().required('This field is required').email('Invalid email address'),
      domains: yup.array().when('metrical', {
        is: false,
        then: (schema) => schema.min(1, 'Select at least one domain'),
      }),
    })),
  });
  const {
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    setError,
  } = formContext;
  const watchOrgs = watch('organizations');
  const domains = watchOrgs.flatMap((x) => (
    profile.organizations.find((y) => y.id === x)!.domains
  ));

  const [isMultiOrgs, setIsMultiOrgs] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (watchOrgs) {
      if (watchOrgs.length > 1) {
        setIsMultiOrgs(true);
      } else {
        setIsMultiOrgs(false);
      }
    }
  }, [watchOrgs]);

  useEffect(() => {
    setIsOpen(isMultiOrgs);
  }, [isMultiOrgs]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setValue('firstName', params.get('firstName') ?? '');
    setValue('lastName', params.get('lastName') ?? '');
    setValue('email', params.get('email') ?? '');
  }, []);
  
  useEffect(() => {
    setValue('domains', getValues().domains.filter((x) => domains.find((y) => y.id === x)));
  }, [watchOrgs]);

  const watchReports = watch('reports');
  const watchAdmin = watch('admin');
  const watchMetrical = watch('metrical');
  useEffect(() => {
    if (watchReports) {
      setValue('admin', false);
      setValue('metrical', false);
    }
  }, [watchReports]);
  useEffect(() => {
    if (watchAdmin || watchMetrical) {
      setValue('reports', false);
    }
    if (watchAdmin && watchMetrical) {
      setValue('organizations', []);
      setValue('domains', []);
    }
  }, [watchAdmin, watchMetrical]);

  const organizations = profile.organizations.filter((x) => !x.master);
  const onSubmit = async (value: UserFormType) => {
    const data = {
      ...value,
      domains: [
        ...(value.metrical ? [
          profile.organizations.find((x) => x.master)!.domains[0].id,
        ] : []),
        ...value.domains,
      ],
    };
    try {
      const { data: { id } } = await createUser(data);
      onAddUser({
        ...data,
        id,
        organizations: [
          ...(value.metrical ? [
            profile.organizations.find((x) => x.master) as IProfileOrganization,
          ] : []),
          ...data.organizations.map((x) => (
            profile.organizations.find((y) => y.id === x) as IProfileOrganization
          )),
        ],
        domains: data.domains.map((x) => (
          profile.organizations
            .flatMap((y) => y.domains)
            .find((y) => y.id === x) as IProfileDomain
        )),
      });
      reset();
    } catch {
      setError('email', { message: 'User already exists' });
    }
  };

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2" textTransform="uppercase">
        Add user
      </Typography>
      <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
        <StyledStack data-testid="roles-page" direction="row" spacing="12px" sx={{ alignItems: 'baseline' }}>
          <StyledTextField
            name="firstName"
            // Disable autocomplete
            label={'First na\u200bme'}
            inputProps={{ name: '' }}
            FormHelperTextProps={{ sx: { position: 'absolute', bottom: '-14px' } }}
            validation={{ required: 'This field is required' }}
          />
          <StyledTextField
            name="lastName"
            label={'Last na\u200bme'}
            inputProps={{ name: '' }}
            FormHelperTextProps={{ sx: { position: 'absolute', bottom: '-14px' } }}
            validation={{ required: 'This field is required' }}
          />
          <StyledTextField
            name="email"
            label={'Ema\u200bil'}
            inputProps={{ name: '' }}
            FormHelperTextProps={{ sx: { position: 'absolute', bottom: '-14px' } }}
            validation={{ required: 'This field is required', validate: (e) => validateEmail(e) }}
          />

          <CheckboxElement
            name="admin"
            label="Admin"
            labelProps={{ componentsProps: { typography: { variant: 'body2' } } }}
          />
          {isMetrical && (
            <CheckboxElement
              name="metrical"
              label="Metrical Associate"
              labelProps={{ componentsProps: { typography: { variant: 'body2' } } }}
            />
          )}
          <CheckboxElement
            name="reports"
            label={'Email\u00a0Reports\u00a0Only'}
            labelProps={{
              componentsProps: { typography: { variant: 'body2' } },
              sx: { width: 'min-content' },
            }}
          />

          <FormSelect.MultiSelect
            name="organizations"
            placeholder="Organizations"
            control={control}
            options={
              organizations ? organizations.map(({ id, name }) => ({ id, label: name })) : []
            }
            formControlSx={{ flex: 1 }}
            showAllItem
            showCheckbox
            disabled={watchAdmin && watchMetrical}
          />
          <FormSelect.MultiSelect
            name="domains"
            placeholder="Domains"
            options={domains.map(({ id, url }) => ({ id, label: url }))}
            formControlSx={{ flex: 1 }}
            showAllItem
            showCheckbox
            disabled={watchAdmin && watchMetrical}
          />

          <Button variant="contained" type="submit">
            Add
          </Button>
        </StyledStack>
      </FormContainer>
      <CustomModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onCancel={() => setValue('organizations', [])}
        content={
          <>
            Providing access across multiple organizations:
            <ul style={{ marginBottom: 0, paddingLeft: 30 }}>
              {watchOrgs &&
                watchOrgs.map((item) => (
                  <li key={item}>
                    {organizations.find(({ id }) => id.toString() === item.toString())?.name}
                  </li>
                ))}
            </ul>
          </>
        }
      />
    </Stack>
  );
}

import type { IOrganization, IOrganizationTableRow } from 'types/organizations';

export function convertOrganizationData(inputArray?: IOrganization[]) {
  const resultArray: IOrganizationTableRow[] = [];
  if (!inputArray) return [];

  [...inputArray].sort(
    (a, b) => a.name.localeCompare(b.name)
  ).forEach(({ id, name, domains }) => {
    resultArray.push({ id, name, canDelete: domains.length === 0 });

    [...domains].sort(
      (a, b) => a.url.localeCompare(b.url)
    ).forEach((
        {
          id: domainId,
          logoId,
          logoUrl,
          prodsId,
          surveyId,
          timezone,
          url,
          name: domainName,
          startDate,
          grossRevViewOnly,
        }) => {
      resultArray.push({
        id: `${id}-${domainId}`,
        name,
        domainName,
        startDate,
        domain: url,
        logoId,
        logoUrl,
        prodsId,
        surveyId,
        timezone,
        grossRevViewOnly,
      });
    });
  });

  return resultArray;
}

import React, { createContext } from 'react';

import { DashboardType } from '../../../types/global';

export interface HeaderContextValue {
  domainId: number;
  setDomainId: React.Dispatch<React.SetStateAction<number>>;
  organizationId: number;
  setOrganizationId: React.Dispatch<React.SetStateAction<number>>;
  dashboardType: DashboardType;
  setDashboardType: React.Dispatch<React.SetStateAction<DashboardType>>;
}

export const HeaderContext = createContext<HeaderContextValue>({
  domainId: 0,
  setDomainId: () => {},
  organizationId: 0,
  setOrganizationId: () => {},
  dashboardType: DashboardType.Incremental,
  setDashboardType: () => {},
});

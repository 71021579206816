import React from 'react';
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrow-forward.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import CustomDataGrid from 'components/DataGrid';
import { dateOperators, stringOperators } from 'components/FilterPanel/operators';
import { FormInput } from 'components/Forms/FormInput';
import dayjs from 'dayjs';
import type { IEditFormStatus, IOrganization } from 'types/organizations';
import { convertOrganizationData } from 'utils/organization';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-pro';

import { useFullYearFormat } from '../../../../utils/format';

type Props = {
  organizations: IOrganization[];
  setEditStatus: (value: IEditFormStatus) => void;
};

function OrganizationsTable({ organizations, setEditStatus }: Props) {
  const dateFormat = useFullYearFormat();
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'ORGANIZATION NAME',
      valueFormatter: (params) => {
        const domain = params.id && params.api.getCellValue(params.id, 'domain');
        return domain === undefined ? params.value : '';
      },
      flex: 1,
      filterOperators: stringOperators,
    },
    {
      field: 'domain',
      headerName: 'DOMAINS',
      renderCell: (params) =>
        params.row.domain !== undefined ? (
          <Typography variant="body">{params.row.domain}</Typography>
        ) : (
          <Button
            sx={{ padding: 0 }}
            onClick={() => setEditStatus({ type: 'domain', data: { id: params.row.id } })}
          >
            + domain
          </Button>
        ),
      flex: 1,
      filterOperators: stringOperators,
    },
    {
      field: 'startDate',
      headerName: 'SUBSCRIPTION START',
      type: 'date',
      valueGetter: (params) => (params.row.domain === undefined ? null : dayjs(params.value)),
      valueFormatter: ({ value }) => value && value.format(dateFormat),
      flex: 1,
      filterOperators: dateOperators,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 50,
      renderCell: (params) => [
        <IconButton
          key={params.id}
          onClick={() =>
            setEditStatus({
              type: params.row.domain !== undefined ? 'domain' : 'organization',
              data: params.row,
            })
          }
        >
          <ArrowForwardIcon />
        </IconButton>,
      ],
    },
  ];

  const [searchText, setSearchText] = React.useState('');
  const deferredSearchText = React.useDeferredValue(searchText.toLowerCase());
  const convertedData = convertOrganizationData(organizations);
  const tableData = React.useMemo(
    () =>
      convertedData.filter((item) => {
        const isDomain = item.domain?.toLowerCase().includes(deferredSearchText);
        const isName = item.name?.toLowerCase().includes(deferredSearchText);
        const parentIds = convertedData
          .filter((element) => element.domain?.toLowerCase().includes(deferredSearchText))
          .map((parent) => (parent.id as string).split('-')[0]);
        const isParent = parentIds.includes(item.id.toString());
        return isDomain || isName || isParent;
      }),
    [deferredSearchText, convertedData]
  );

  return (
    <Stack spacing="18px">
      <FormInput.TextFieldBase
        value={searchText}
        variant="outlined"
        testID="search-input"
        handleChange={setSearchText}
        InputProps={{
          sx: {
            '&> input::placeholder': {
              color: 'black',
            },
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search for organization or domain"
      />
      <Card sx={{ bgcolor: 'white' }}>
        <CustomDataGrid columns={columns} rows={tableData} autoHeight hideFooter />
      </Card>
    </Stack>
  );
}

export default OrganizationsTable;

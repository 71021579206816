import 'jimp';

declare global {
  interface Window {
    Jimp: any;
  }
}

const { Jimp } = window;

const MAX_SIZE = 200 * 1024;

export default async function resizeImage(file: File) {
  if (file.size <= MAX_SIZE) {
    return file;
  }
  const image = await Jimp.read({
    url: URL.createObjectURL(file),
  });
  let newFile = new File([await image.getBufferAsync(Jimp.AUTO)], file.name);
  while (newFile.size > MAX_SIZE) {
    image.scale(Math.min(0.95, Math.sqrt(MAX_SIZE / newFile.size)));
    // eslint-disable-next-line no-await-in-loop
    newFile = new File([await image.getBufferAsync(Jimp.AUTO)], file.name);
  }
  return newFile;
}

import React, { useEffect, useMemo, useState } from 'react';
import { FormContainer, SelectElement, TextFieldElement, useForm } from 'react-hook-form-mui';
import { getProductsAPI, updateDomainAPI } from 'apis/domains';
import { getOrganizationsAPI } from 'apis/organizations';
import { FormInput } from 'components/Forms/FormInput';
import { FormSelect } from 'components/Forms/FormSelect';
import dayjs from 'dayjs';
import { IDomain, IProduct } from 'types/domains';
import { IOrganization } from 'types/organizations';

import CircleIcon from '@mui/icons-material/Circle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useFullYearFormat } from '../../utils/format';

const CustomStack = styled(Stack)({
  maxWidth: 400,
  justifyContent: 'flex-start',
  gap: 16,
  marginTop: 60,
  marginLeft: 'auto',
  marginRight: 'auto',
});

const StyledCircleIcon = styled(CircleIcon)({
  width: 6,
  height: 6,
  margin: '6px 5px',
  color: '#64C155',
});

type FormFields = {
  organization?: number;
  domain?: number | '';
  url: string;
  name: string;
  logoId: string | null;
  logoUrl: string | null;
  timezone: string;
  startDate: string;
};

export default function CustomerOrganizationPage() {
  const dateFormat = useFullYearFormat();
  const formContext = useForm<FormFields>();
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [organization, setOrganization] = useState<IOrganization>();
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [domain, setDomain] = useState<IDomain>();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, isValid },
    watch,
    reset,
  } = formContext;

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const orgResponse = await getOrganizationsAPI();
      setOrganizations(orgResponse.data);

      const domainResponse = await getProductsAPI();
      setProducts(domainResponse.data);
    } catch { /* empty */ } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Select first organization when organization data fetching is finished
  useEffect(() => {
    if (!isLoading && organizations.length > 0) {
      setOrganization(organizations[0]);
    }
  }, [isLoading]);

  // Set organization name and domain according to organization selection
  useEffect(() => {
    setValue('organization', organization?.id);

    setDomains(organization?.domains || []);

    if (organization?.domains && organization?.domains.length > 0) {
      setDomain(organization?.domains[0]);
    } else {
      setDomain(undefined);
    }
  }, [organization]);

  // Select domain timezone according to domain selection
  useEffect(() => {
    setValue('url', domain?.url || '');
    setValue('name', domain?.name || '');
    setValue('domain', domain ? domain.id : '');
    setValue('logoId', domain?.logoId ?? null);
    setValue('logoUrl', domain?.logoUrl ?? null);
    setValue('timezone', domain ? domain.timezone : '');
    setValue('startDate', domain?.startDate || '');
  }, [domain]);

  const watchLogoId = watch('logoId');
  const watchLogoUrl = watch('logoUrl');
  const logo = useMemo(() => (
    (watchLogoId && watchLogoUrl) ? { uploadedId: watchLogoId, uploadedUrl: watchLogoUrl } : null
  ), [watchLogoId, watchLogoUrl]);

  const [updateResult, setUpdateResult] = useState<null | 'success' | 'failure'>(null);

  const onSubmit = async (formData: FormFields) => {
    if (organization && domain) {
      setUpdateResult(null);
      try {
        await updateDomainAPI(`${organization.id}`, `${domain.id}`, {
          ...formData,
          logoId: formData.logoId,
          prodsId: domain.prodsId,
          surveyId: domain.surveyId,
          grossRevViewOnly: domain.grossRevViewOnly,
        });
        reset({
          organization: organization.id,
          domain: domain.id,
          url: domain.url,
          name: formData.name,
          logoId: formData.logoId,
          logoUrl: formData.logoUrl,
          timezone: formData.timezone,
          startDate: domain.startDate,
        });
        setUpdateResult('success');
      } catch {
        setUpdateResult('failure');
      }
    }
  };

  return (
    <Stack sx={{ marginBottom: 3 }}>
      <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
        <CustomStack>
          <FormControl>
            <SelectElement
              name="organization"
              options={organizations.map(({ id, name }) => ({ id, label: name }))}
              onChange={(value: number) =>
                setOrganization(organizations.find(({ id }) => id === value))
              }
              data-testid="select-domain"
            />
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              Customer since {dayjs(domain?.startDate ?? '').format(dateFormat)}
            </Typography>
          </FormControl>

          <SelectElement
            name="domain"
            options={domains.map(({ id, url }) => ({ id, label: url }))}
            onChange={(value) => setDomain(domains.find(({ id }) => id === value))}
          />

          <TextFieldElement name="name" label="Domain Display Name" />

          <Stack>
            <Typography variant="body2">Products</Typography>

            <List>
              {domain?.prodsId && domain?.prodsId.length > 0 ? (
                domain?.prodsId.map((prodId) => (
                  <ListItem disablePadding key={prodId}>
                    <StyledCircleIcon />
                    <Typography variant="caption">
                      {products.find(({ id }) => id === prodId)?.name}
                    </Typography>
                  </ListItem>
                ))
              ) : (
                <Typography variant="caption">--- No product ---</Typography>
              )}
            </List>
          </Stack>

          <Stack direction="row" spacing={2} marginY={2}>
            <FormInput.ImageUploader
              control={control}
              name="logo"
              setValue={(value) => {
                setValue('logoId', value?.uploadedId ?? null);
                setValue('logoUrl', value?.uploadedUrl ?? null);
              }}
              logo={logo}
            />
            <Typography variant="body2">
              Recommended image size: 680x340 px. <br />
              File formats: png, jpg <br />
            </Typography>
          </Stack>

          <Typography variant="body2">Timezone for reporting data</Typography>

          <FormSelect.TimezoneSelect
            name="timezone"
            onInputChange={(value) => setValue('timezone', value)}
            placeholder="Search for location"
            required
          />

          <Stack direction="row" marginLeft="auto" marginTop={7}>
            <Button
              variant="text"
              sx={{ marginRight: 1 }}
              onClick={() => {
                if (organization && domain) {
                  reset({
                    organization: organization.id,
                    domain: domain.id,
                    url: domain.url,
                    name: domain.name,
                    logoId: domain.logoId,
                    logoUrl: domain.logoUrl,
                    timezone: domain.timezone,
                    startDate: domain.startDate,
                  });
                }
              }}
            >
              Discard changes
            </Button>
            <Button variant="contained" type="submit" disabled={!isDirty || !isValid}>
              Save
            </Button>
          </Stack>
          {updateResult && (
            <Typography variant="body2" sx={{ marginTop: 1, textAlign: 'right', fontSize: '12px' }}>
              {updateResult === 'success' ? 'Changes have been saved.' : 'Changes could not be saved.'}
            </Typography>
          )}
        </CustomStack>
      </FormContainer>
    </Stack>
  );
}

import React from 'react';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as ExitFullScreenIcon } from 'assets/icons/exit-full-screen.svg';
import { ReactComponent as FullScreenIcon } from 'assets/icons/full-screen.svg';
import { ReactComponent as NotVisibleIcon } from 'assets/icons/not-visible.svg';

import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, Popover, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid-pro';

interface SideButtonProps {
  allRows: boolean;
  setAllRows: React.Dispatch<React.SetStateAction<boolean>>;
  hiddenColumns: string[];
  setHiddenColumns: React.Dispatch<React.SetStateAction<string[]>>;
  columns: GridColDef[];
  isFullscreen: boolean;
  setIsFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
  onDownload: (isAll: boolean) => void;
}

export default function SideButtons(props: SideButtonProps) {
  const {
    allRows,
    setAllRows,
    hiddenColumns,
    setHiddenColumns,
    columns,
    isFullscreen,
    setIsFullscreen,
    onDownload,
  } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'toggle-columns-popover' : undefined;
  const [downloadAnchorEl, setDownloadAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const downloadOpen = Boolean(downloadAnchorEl);
  const downloadId = downloadAnchorEl ? 'download-menu' : undefined;
  return (
    <Box
      sx={{
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        padding: '4px 0',
        flexDirection: 'row-reverse',
        marginLeft: 'auto',
        alignSelf: 'center',
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      }}
    >
      <IconButton
        size="small"
        onClick={() => setIsFullscreen(!isFullscreen)}
        aria-label="Toggle fullscreen"
      >
        {isFullscreen ? <ExitFullScreenIcon /> : <FullScreenIcon />}
      </IconButton>
      <IconButton
        size="small"
        aria-label="Download"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setDownloadAnchorEl(event.currentTarget);
        }}
      >
        <DownloadIcon />
      </IconButton>
      <Menu
        id={downloadId}
        open={downloadOpen}
        anchorEl={downloadAnchorEl}
        onClose={() => setDownloadAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            onDownload(false);
            setDownloadAnchorEl(null);
          }}
        >
          Visible data
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDownload(true);
            setDownloadAnchorEl(null);
          }}
        >
          All data
        </MenuItem>
      </Menu>
      {allRows && (
        <>
          <IconButton
            size="small"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget);
            }}
            aria-label="Toggle columns"
          >
            <NotVisibleIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              sx: {
                padding: '16px 18px',
                border: 'none',
              },
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: 800,
                fontVariant: 'all-small-caps',
                color: theme.palette.primary.main,
                marginBottom: '8px',
              }}
            >
              Hide / show columns
            </Typography>
            <FormGroup
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                setHiddenColumns((currentState) => (
                  currentState.includes(target.name)
                    ? currentState.filter((x) => x !== target.name)
                    : [...currentState, target.name]
                ));
              }}
            >
              {columns
                .filter((x) => !['id', 'campaign', 'device'].includes(x.field))
                .map((column) => (
                  <FormControlLabel
                    key={column.field}
                    control={<Checkbox />}
                    label={column.headerName}
                    name={column.field}
                    checked={!hiddenColumns.includes(column.field)}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '12px',
                        lineHeight: '18px',
                        fontVariant: 'all-small-caps',
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                ))}
            </FormGroup>
          </Popover>
        </>
      )}
      <IconButton
        size="small"
        onClick={() => setAllRows(!allRows)}
        aria-label="Show all rows"
      >
        <Box component={allRows ? ArrowLeftIcon : ArrowRightIcon} sx={{ width: '20px', height: '20px' }} />
      </IconButton>
    </Box>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import useEventListener from '@use-it/event-listener';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import dayjs, { Dayjs } from 'dayjs';

import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';
import TextField, { TextFieldProps as TextFieldPropsInterface } from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';
import {
  DateRange,
  DateRangeCalendar, MobileDateRangePicker,
  PickersShortcuts,
  PickersShortcutsItem,
} from '@mui/x-date-pickers-pro';
import { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internal/models';

import { useFullYearFormat, useNoYearDateFormat } from '../../utils/format';

interface DateRangePickerProps {
  value: DateRange<Dayjs>;
  onChange: (newValue: NonEmptyDateRange<Dayjs>) => void;
  TextFieldProps?: TextFieldPropsInterface;
}

export const formatValue = (value: DateRange<Dayjs>, fullYearFormat: string, noYearFormat: string) => {
  if (!value[0]) {
    return '';
  }
  if (!value[1]) {
    return value[0].format(fullYearFormat);
  }
  if (value[0].isSame(value[1], 'day')) {
    return value[0].format(fullYearFormat);
  }
  if (value[0].isSame(value[1], 'year')) {
    return `${value[0].format(noYearFormat)} — ${value[1].format(fullYearFormat)}`;
  }
  return `${value[0].format(fullYearFormat)} — ${value[1].format(fullYearFormat)}`;
};

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Yesterday',
    getValue: () => {
      const yesterday = dayjs().subtract(1, 'day');
      return [yesterday, yesterday];
    },
  },
  {
    label: 'This week',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: 'Last week',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: 'This month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today];
    },
  },
  {
    label: 'Last month',
    getValue: () => {
      const prevMonth = dayjs().subtract(1, 'month');
      return [prevMonth.startOf('month'), prevMonth.endOf('month')];
    },
  },
  {
    label: 'Last 7 days',
    getValue: () => {
      const yesterday = dayjs().subtract(1, 'day');
      return [yesterday.subtract(6, 'day'), yesterday];
    },
  },
  {
    label: 'Last 30 days',
    getValue: () => {
      const yesterday = dayjs().subtract(1, 'day');
      return [yesterday.subtract(29, 'day'), yesterday];
    },
  },
];

export default function DateRangePicker({ value, onChange, TextFieldProps }: DateRangePickerProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const fullYearFormat = useFullYearFormat();
  const noYearFormat = useNoYearDateFormat();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const ignoreFocus = useRef(false);
  const [innerValue, setInnerValue] = useState<DateRange<Dayjs>>(value);
  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const handleFocus = (event: React.FocusEvent<HTMLElement> | React.MouseEvent<HTMLElement>) => {
    if (
      (!ignoreFocus.current ||
      (event.type === 'click' && 'button' in event && event.button === 0))
      && !TextFieldProps?.disabled
    ) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    ignoreFocus.current = true;
    setAnchorEl(null);
    setTimeout(() => {
      ignoreFocus.current = false;
    }, 1);
  };

  useEventListener('focus', () => {
    ignoreFocus.current = true;
    setTimeout(() => {
      ignoreFocus.current = false;
    }, 1);
  });

  const open = Boolean(anchorEl);
  const id = open ? 'data-range-popover' : undefined;
  if (isDesktop) {
    return (
      <>
        <TextField
          aria-describedby={id}
          onFocus={handleFocus}
          onClick={handleFocus}
          value={formatValue(value, fullYearFormat, noYearFormat)}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end" sx={{ color: 'inherit' }}>
                <CalendarIcon />
              </InputAdornment>
            ),
          }}
          {...TextFieldProps}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: (anchorEl?.clientHeight || 0) + 5,
            horizontal: 'center',
          }}
          PaperProps={{ sx: { display: 'flex' } }}
          onClick={(event) => {
            if ((event.target as HTMLElement).matches('.MuiPickersArrowSwitcher-root .MuiTypography-subtitle1')) {
              const month = dayjs((event.target as HTMLElement).textContent, 'MMMM YYYY');
              onChange([dayjs(month).startOf('month'), dayjs(month).endOf('month')]);
            }
          }}
        >
          <PickersShortcuts
            items={shortcutsItems}
            onChange={(newValue) => {
              if (newValue[0] && newValue[1]) {
                onChange([newValue[0], newValue[1]]);
              }
              handleClose();
            }}
            isLandscape
            isValid={() => true}
            sx={{
              padding: '42px 0 10px 0',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              borderRight: '1px solid rgba(145, 158, 171, 0.24)',
              '& .MuiListItem-root': {
                margin: '4px 0',
              },
              '& .MuiChip-root': {
                color: theme.palette.primary.main,
                background: 'none',
                fontWeight: 400,
                fontSize: 14,
                boxShadow: 'none',
              },
            }}
          />
          <DateRangeCalendar
            value={innerValue}
            onChange={(newValue, selectionState) => {
              setInnerValue(newValue);
              if (newValue[0] && newValue[1]) {
                onChange([newValue[0], newValue[1]]);
              }
              if (selectionState === 'finish') {
                handleClose();
              }
            }}
            disableHighlightToday
            disableFuture
          />
        </Popover>
      </>
    );
  }
  return (
    <MobileDateRangePicker
      value={innerValue}
      onChange={(newValue) => {
        setInnerValue(newValue);
        if (newValue[0] && newValue[1]) {
          onChange([newValue[0], newValue[1]]);
        }
      }}
      disableHighlightToday
      disableFuture
      localeText={{ start: '', end: '' }}
    />
  );
}

import React, { useContext } from 'react';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import dayjs, { Dayjs } from 'dayjs';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import DashboardContext from '../../pages/DashboardContext';
import { useFullYearFormat } from '../../utils/format';

export default function EndsCell({ value, formattedValue }: GridRenderCellParams<any, Dayjs>) {
  const { dateRange } = useContext(DashboardContext);
  const dateFormat = useFullYearFormat();
  if (!value || !formattedValue) {
    return null;
  }
  const isActive = value && dayjs(value).isAfter(dayjs());
  const isIncomplete = value && dateRange[1] && dayjs(value).isAfter(dateRange[1])
    && dayjs(dateRange[1]).isBefore(dayjs().startOf('day'));
  return (
    <>
      <Tooltip title={isActive ? 'Active campaign' : ''}>
        <Box
          sx={{
            padding: '4px',
            marginLeft: '-4px',
          }}
        >
          <Box
            sx={{
              width: '5px',
              height: '5px',
              borderRadius: '50%',
              backgroundColor: isActive ? '#00C853' : 'transparent',
              flexShrink: 0,
            }}
          />
        </Box>
      </Tooltip>
      {formattedValue}
      {isIncomplete && (
        <Tooltip
          title={
            `Table shows data until ${dayjs(dateRange[1]).format(dateFormat)}.
            For complete campaign data, change the selected period.`
          }
        >
          <Box component={WarningIcon} sx={{ marginLeft: '4px', flexShrink: 0 }} />
        </Tooltip>
      )}
    </>
  );
}

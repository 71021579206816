import React, { useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';

import Button from '@mui/material/Button';

import { SessionContext } from '../../auth';
import { StyledTypography } from '../../components/Auth/Styled';
import paths from '../../routes/paths';

export default function AccessPending() {
  const { session, profile } = useContext(SessionContext);
  if (profile && profile.organizations.length > 0) {
    return (
      <Navigate to={paths.dashboard.root} />
    );
  }
  const emailVerified = session?.user?.app_metadata.provider === 'email'
    && session?.user?.email_confirmed_at !== undefined;
  return (
    <>
      <StyledTypography>
        {emailVerified && 'Your email has been verified. '}
        You will receive an email once access has been approved by the administrator.
      </StyledTypography>
      <Button
        variant="contained"
        component={Link}
        to={paths.auth.login}
        sx={{ alignSelf: 'center', marginTop: '20px' }}
      >
        Go to login page
      </Button>
    </>
  );
}

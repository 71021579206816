import React, { useState } from 'react';
import { TextFieldElement, TextFieldElementProps } from 'react-hook-form-mui';
import { ReactComponent as NotVisibleIcon } from 'assets/icons/not-visible.svg';
import { ReactComponent as VisibleIcon } from 'assets/icons/visible.svg';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

export default function PasswordField(props: TextFieldElementProps) {
  const [visible, setVisible] = useState(false);
  const { InputProps = {}, sx = {}, ...rest } = props;
  return (
    <TextFieldElement
      {...rest}
      type={visible ? 'text' : 'password'}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={() => setVisible(!visible)}>
              {visible ? <NotVisibleIcon style={{ width: '16px' }} /> : <VisibleIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        '& .MuiInputBase-root': {
          paddingRight: '7px',
        },
        ...sx,
      }}
    />
  );
}

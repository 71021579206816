import { Theme } from '@mui/material/styles';

import Button from './Button';
import Checkbox from './CheckBox';
import dataGridOverrides from './DataGrid';
import DatePickers from './DatePickers';
import Input from './Input';
import Menu from './Menu';
import sliderOverrides from './Slider';
import tooltipOverrides from './Tooltip';

export default function ComponentOverrides(theme: Theme) {
  return Object.assign(
    Input(theme),
    Checkbox(theme),
    Button(theme),
    Menu(theme),
    DatePickers(),
    dataGridOverrides,
    sliderOverrides,
    tooltipOverrides,
  );
}

import React from 'react';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import {
  createOrganizationAPI,
  removeOrganizationAPI,
  updateOrganizationAPI,
} from 'apis/organizations';
import { CustomModal } from 'components/CustomModal';
import dayjs, { Dayjs } from 'dayjs';
import type { IOrganizationTableRow } from 'types/organizations';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Stack } from '@mui/system';

import { useUpdateProfile } from '../../../auth';

type Props = {
  data?: IOrganizationTableRow;
  updateData: () => void;
};

type OrganizationForm = {
  name: string;
  startDate: Dayjs | null;
  grossRevViewOnly: boolean;
};

function EditOrganizationForm({ data, updateData }: Props) {
  const formContext = useForm<OrganizationForm>({ defaultValues: { grossRevViewOnly: false } });
  const {
    handleSubmit,
    reset: formReset,
    formState: { isDirty, isValid },
  } = formContext;

  const [isDeleteModalOpened, setIsDeleteModalOpened] = React.useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = React.useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);

  const updateProfile = useUpdateProfile();
  const onSubmit = async ({ name }: OrganizationForm) => {
    const params = { name };

    const response = data
      ? await updateOrganizationAPI(data.id, params)
      : await createOrganizationAPI(params);

    if (response) updateData();
    await updateProfile();
  };

  const onRemove = async (id: string | number) => {
    if (typeof id === 'number') {
      const response = await removeOrganizationAPI(id);
      if (response) updateData();
      await updateProfile();
    }
  };

  React.useEffect(() => {
    formReset({ ...data, startDate: data ? dayjs(data?.startDate) : null });
  }, [data]);

  return (
    <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
      <Stack spacing='18px'>
        <TextFieldElement
          label='Organization name'
          name='name'
          required
          InputLabelProps={{ required: false }}
        />
        <Stack justifyContent='space-between' direction='row'>
          {data?.id ? (
            <Button
              variant='text'
              color='error'
              onClick={() => {
                if (!data.canDelete) {
                  setIsErrorDialogOpen(true);
                } else {
                  setIsDeleteModalOpened(true);
                }
              }}
            >
              Delete organization
            </Button>
          ) : (
            <Box />
          )}
          <Box>
            {isDirty ? (
              <Button
                variant='text'
                onClick={() =>
                  formReset({ ...data, startDate: data ? dayjs(data?.startDate) : null })
                }
              >
                Discard changes
              </Button>
            ) : null}
            <Button
              variant='contained'
              type='submit'
              sx={{ marginX: 2 }}
              disabled={data ? !isDirty : !isValid}
            >
              Save
            </Button>
          </Box>
        </Stack>
      </Stack>
      {data ? (
        <>
          <CustomModal
            open={isDeleteModalOpened}
            onClose={() => setIsDeleteModalOpened(false)}
            onCancel={() => setIsConfirmModalOpened(true)}
            content={
              <>
                All users assigned to {data.name} and its domains will lose access to Metrical.
                <br /> <br /> Data for {data.name} will become inaccessible.
              </>
            }
            actionButtonLabels={{ cancel: 'delete', confirm: 'cancel' }}
          />
          <CustomModal
            open={isConfirmModalOpened}
            onClose={() => {
              setIsConfirmModalOpened(false);
              setIsDeleteModalOpened(false);
            }}
            onCancel={() => onRemove(data.id)}
            content={`Are you sure you want to delete ${data.name} from Metrical?`}
            actionButtonLabels={{ cancel: 'delete', confirm: 'cancel' }}
          />
          <Dialog
            open={isErrorDialogOpen}
            onClose={() => setIsErrorDialogOpen(false)}
          >
            <DialogContent>
              <DialogContentText>
                To delete an organization, delete all of its domains first.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="text" onClick={() => setIsErrorDialogOpen(false)}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </FormContainer>
  );
}

export default EditOrganizationForm;
